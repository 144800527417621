.recykal{

  @media only screen
  and (max-device-height: 669px) {
    *[page-bg-img]{
      --background: unset;
    }
    .bottom-img{
      display: none;
    }
  }
  
  
  /* Orientation landscape doesn't matter what height it is*/
  @media screen and (orientation: landscape) {
    .page-content-center{
      top: unset;
      transform: unset;
    }
    *[page-bg-img]{
      --background: unset;
    }
    .bottom-img{
      display: none;
    }
  }



  /* Standard resolutions */

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {

  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {

  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {}

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {}

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {}


  /* Device specific resolutions */

  /* ----------- iPhone 4 and 4S ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
  }

  /* Landscape */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

  }

  /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-height: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .bottom-img{
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
  }

  /* Landscape */
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

  }

  /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

  }

  /* ----------- iPhone 6+, 7+ and 8+ ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {

  }

  /* ----------- iPhone X ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {

  }





  /* ----------- Galaxy S3 ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2) {

  }

  /* Portrait */
  @media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2)
  and (orientation: landscape) {

  }

  /* ----------- Galaxy S4, S5 and Note 3 ----------- */

  /* Portrait and Landscape */
  @media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {

  }

  /* Portrait */
  @media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: landscape) {

  }

  /* ----------- Galaxy S6 ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {

  }

  /* Portrait */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4)
  and (orientation: landscape) {

  }



  /* ----------- Google Pixel ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {

  }

  /* Portrait */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: landscape) {

  }

  /* ----------- Google Pixel XL ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {

  }

  /* Portrait */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4)
  and (orientation: landscape) {

  }



  /* ----------- HTC One ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {

  }

  /* Portrait */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: landscape) {

  }



  /* ----------- Windows Phone ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 480px)
  and (device-height: 800px) {

  }

  /* Portrait */
  @media screen
  and (device-width: 480px)
  and (device-height: 800px)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 480px)
  and (device-height: 800px)
  and (orientation: landscape) {

  }




  /* ----------- iPad 1, 2, Mini and Air ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 1) {

  }

  /* Landscape */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) {

  }

  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Landscape */
  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* ----------- iPad Pro 10.5" ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 834px)
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen
  and (min-device-width: 834px)
  and (max-device-width: 834px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen
  and (min-device-width: 1112px)
  and (max-device-width: 1112px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* ----------- iPad Pro 12.9" ----------- */

  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1024px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  @media only screen
  and (min-device-width: 1366px)
  and (max-device-width: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

  }




  /* ----------- Galaxy Tab 2 ----------- */
  /* Portrait and Landscape */
  @media
  (min-device-width: 800px)
  and (max-device-width: 1280px) {

  }

  /* Portrait */
  @media
  (max-device-width: 800px)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media
  (max-device-width: 1280px)
  and (orientation: landscape) {

  }

  /* ----------- Galaxy Tab S ----------- */
  /* Portrait and Landscape */
  @media
  (min-device-width: 800px)
  and (max-device-width: 1280px)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Portrait */
  @media
  (max-device-width: 800px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2) {

  }

  /* Landscape */
  @media
  (max-device-width: 1280px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2) {

  }




  /* ----------- Nexus 7 ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 601px)
  and (device-height: 906px)
  and (-webkit-min-device-pixel-ratio: 1.331)
  and (-webkit-max-device-pixel-ratio: 1.332) {

  }

  /* Portrait */
  @media screen
  and (device-width: 601px)
  and (device-height: 906px)
  and (-webkit-min-device-pixel-ratio: 1.331)
  and (-webkit-max-device-pixel-ratio: 1.332)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 601px)
  and (device-height: 906px)
  and (-webkit-min-device-pixel-ratio: 1.331)
  and (-webkit-max-device-pixel-ratio: 1.332)
  and (orientation: landscape) {

  }

  /* ----------- Nexus 9 ----------- */
  /* Portrait and Landscape */
  @media screen
  and (device-width: 1536px)
  and (device-height: 2048px)
  and (-webkit-min-device-pixel-ratio: 1.331)
  and (-webkit-max-device-pixel-ratio: 1.332) {

  }

  /* Portrait */
  @media screen
  and (device-width: 1536px)
  and (device-height: 2048px)
  and (-webkit-min-device-pixel-ratio: 1.331)
  and (-webkit-max-device-pixel-ratio: 1.332)
  and (orientation: portrait) {

  }

  /* Landscape */
  @media screen
  and (device-width: 1536px)
  and (device-height: 2048px)
  and (-webkit-min-device-pixel-ratio: 1.331)
  and (-webkit-max-device-pixel-ratio: 1.332)
  and (orientation: landscape) {

  }



  /* ----------- Kindle Fire HD 7" ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 800px)
  and (max-device-width: 1280px)
  and (-webkit-min-device-pixel-ratio: 1.5) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 800px)
  and (max-device-width: 1280px)
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: portrait) {
  }

  /* Landscape */
  @media only screen
  and (min-device-width: 800px)
  and (max-device-width: 1280px)
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) {

  }

  /* ----------- Kindle Fire HD 8.9" ----------- */
  /* Portrait and Landscape */
  @media only screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 1.5) {

  }

  /* Portrait */
  @media only screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: portrait) {
  }

  /* Landscape */
  @media only screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 1.5)
  and (orientation: landscape) {

  }

}

//desktop media queries start
.recykal.recykal-desktop {

  @media only screen
  and (min-width: 1030px)
  and (max-width: 1366px) {
    .desktop-commom-footer {
      line-height: 0;
      position: static;
      height: 100px;
      background: url('./assets/imgs/desktop-footer-bg.png');
      background-size: contain;
      bottom: 0;
      background-color: #fff;
      /*margin-right: -15px;
      margin-left: -15px;*/
      margin-top: 10px;
    }

  }

  @media only screen
  and (min-width: 1370px)
  and (max-width: 1605px) {
    .desktop-commom-footer {
      line-height: 0;
      position: absolute;
      height: 100px;
      background: url('./assets/imgs/desktop-footer-bg.png');
      background-size: contain;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .pickup-flow-content {
      .content-data-section {
        height: 81% !important;
      }
    }
  }

}
//desktop media queries end
