:root {
  .ui-widget {
    font-family: inherit !important;

    .ui-table-loading-content {
      top: 130px !important;
    }

    &.ui-table table {
      table-layout: auto;

      &.ui-table-scrollable-header-table, &.ui-table-scrollable-body-table {
        table-layout: fixed !important;
      }

      .ui-table-tbody > tr > td {
        border: none !important;
      }

      thead tr:nth-child(2) {
        th {
          background-color: #FFFFFF !important;
        }

        border-bottom: 2px solid #dcdcdc;

        input, select, p-multiselect .ui-multiselect-label {
          overflow: hidden;
          height: 28px;
          padding-left: 10px;
          padding-right: 10px;
          background: #FFFFFF;
          font-size: 0.867rem;
          width: 100%;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          -moz-box-orient: vertical;
          display: -webkit-box;
          border: 1px solid #E1E6E8;
          color: #000000;
          border-radius: 5px;
        }

        input[type=text], input[type=search] {
          //background-image: url('assets/search-icon-grey.svg') !important;
          background-repeat: no-repeat !important;
          background-position: right !important;
          background-size: 20px !important;

          &:focus {
            background-image: none !important;
          }
        }

        input[type=search] {
          &::-webkit-search-cancel-button {
            -webkit-appearance: searchfield-cancel-button;
          }

          &:hover {
            background-image: none !important;
          }
        }
      }
    }

    tbody tr.ui-state-highlight,
    thead th.ui-state-highlight {
      background-color: transparent;
      color: inherit !important;
    }

    thead tr th {
      border-left: none;
      border-right: none;
      border-top: none;
      background-color: #fff;
      color: #4e4d4d;
      text-align: left;
      border: none;

      .ui-sortable-column {
        &.ui-state-highlight, &:hover {
          background: none;
        }
      }

      .ui-sortable-column-icon {
        color: inherit !important;
      }

      .ui-sortable-column-icon:before {
        font-size: 0.8571rem;
      }

      p {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }

    tbody td {
      border-left: none;
      border-right: none;
      border-bottom: none;
      background-color: white;
      height: 45px;
      text-align: left;
      color: var(--ion-color-medium);
      font-size: 0.8571rem;

      ion-text {
        line-height: 16px;

        &.wrap-text {
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 30px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

    }

    tbody tr {
      background: none;
      height: 52px;

      p {
        line-height: 17px;
        font-size: 0.8571rem
      }
    }

    .ui-paginator-bottom {
      padding: 2px;
      border: none;
      border-top: unset !important;
      background: #fff;
      /*      position: fixed;
            bottom: 25px;
            right: 30px;*/
    }

    .ui-datepicker {
      padding: 3px;
      width: 261px;;

      table {
        font-size: 0.7857rem;

        td {
          padding: 0
        }
      }
    }

    .ui-paginator {
      border: 1px solid #C7D2D8 !important;
      width: auto;
      float: right;
      margin: 10px 0;
      border-radius: 5px;

      .ui-paginator-pages .ui-paginator-page {
        font-size: 0.8571rem
      }

      .ui-paginator-pages .ui-paginator-page.ui-state-active {
        background-color: var(--ion-color-medium);
        border-radius: 5px;
        font-size: 0.8571rem
      }

      .ui-paginator-first, .ui-paginator-last {
        display: none;
      }

      .ui-paginator-bottom {
        border-top: unset !important;
      }

      .ui-paginator-prev, .ui-paginator-next {
        font-size: 0.8571rem
      }

      .pi-caret-left {
        border-right: 1px solid var(--ion-color-medium);
      }

      .pi-caret-right {
        border-left: 1px solid var(--ion-color-medium);
      }

      .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
        background-color: transparent;
      }

    }

    .ui-table-wrapper, .ui-table-scrollable-wrapper {
      table {

        thead {
          .ui-dropdown {
            min-width: 100px;

            .ui-dropdown-item {
              font-size: var(--ion-regular-font-size);
            }

            .ui-dropdown-trigger-icon {
              font-size: 0.8rem;
            }

            label.ui-dropdown-label {
              font-size: var(--ion-regular-font-size);
              padding: 4px 10px !important;
            }
          }

          tr {
            th {
              background-color: var(--ion-color-medium) !important;
              color: #fff !important;
              font-size: 0.8571rem;
              font-weight: 300;
              padding-right: 0;

              &:last-child {
                padding-right: 10px;
              }

              &.filter-col {
                padding: 5px !important;.ui-autocomplete {
                  ul {
                    border: none;
                    padding: 0;

                    li.ui-autocomplete-input-token {
                      input {
                        padding: 0 10px;
                      }
                    }
                  }
                }
                .ui-multiselect {
                  border: none;
                  width: 95%;
                  min-width: 100px;

                  .ui-multiselect-items-wrapper {
                    min-width: 140px;
                  }

                  .ui-multiselect-label-container {
                    .ui-multiselect-label {
                      margin: 0;
                      padding: 5px 10px !important;
                      color: var(--ion-color-dark);
                    }
                  }

                  .ui-multiselect-filter-container {
                    input {
                      background-image: none !important;
                    }

                    background: none;
                  }

                  .ui-multiselect-trigger {
                    border: 1px solid #E1E6E8;

                    .ui-multiselect-trigger-icon {
                      font-size: 0.8571rem
                    }
                  }
                }

                .custom-slider-range {
                  .selected-values {
                    color: var(--ion-color-medium);
                    height: 20px;
                    margin-bottom: 5px;
                    margin-top: -10px;

                    .left {
                      float: left;
                    }

                    .right {
                      float: right;
                    }
                  }

                  .custom-slider-selector {
                    width: 85%;
                    margin: 0 auto;
                  }
                }

                .delay-section {
                  .selected-values {
                    color: var(--ion-color-medium);
                    height: 20px;
                    margin-bottom: 5px;
                    margin-top: -10px;

                    .left {
                      float: left;
                    }

                    .right {
                      float: right;
                    }
                  }

                  .custom-slider-selector {
                    width: 85%;
                    margin: 0 auto;
                  }

                  .ui-dropdown {
                    border-right: 0 !important;
                    border-radius: 5px 0px 0 5px;
                    min-width: auto;
                    border-color: #E1E6E8;
                    height: 28px;
                    background: #f9f9f9;

                    .ui-dropdown-label {
                      background: #f9f9f9;
                    }

                    .ui-dropdown-trigger {
                      display: none;
                    }
                  }

                  .delay-text {
                    border-radius: 0 5px 5px 0;
                  }

                }

                .ui-slider {
                  .ui-slider-range {
                    background-color: var(--ion-color-primary);
                  }

                  .ui-slider-handle {
                    width: 10px;
                    height: 10px;
                    border: 1px solid #438e69;
                    margin-left: -5px;
                  }
                }
              }

              .ui-datepicker-calendar {
                thead {
                  display: none;
                }
              }
              .ui-dropdown {
                width: 100%;
              }
            }

            th:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;

            }

            th:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }

        tbody {
          tr {
            td:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            td:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }

          .bold-text {
            color: var(--ion-color-dark);
            font-family: 'font-bold';
            font-size: 0.8571rem
          }

          .grey-text {
            color: var(--ion-color-medium);
            font-size: 0.8571rem
          }

          .delay-text {
            color: var(--ion-color-danger);
            font-size: 0.8571rem
          }

          .status-display {
            display: flex;
            align-items: center;
            line-height: 15px;
            text-transform: capitalize;

            .status-dot {
              width: 8px;
              height: 8px;
              margin-right: 7px;
              border-radius: 50%;

              &.created {
                background-color: #72ABEA;
              }

              &.completed {
                background-color: #54B468;
              }

              &.on_hold {
                background-color: #FFD378;
              }

              &.picked {
                background-color: #BEE2C6;
              }

              &.cancelled {
                background-color: #F2503B;
              }

              &.scheduled {
                background-color: #FFA25F;
              }

              &.received {
                background-color: #89CB97;
              }

              &.invoice_pending {
                background-color: #FFEF8C;
              }

              &.payment_pending {
                background-color: #FCD974;
              }

              &.invoice_verification_pending {
                background-color: #FBC45B;
              }

              &.invoice_rejected {
                background-color: #FF7A5F;
              }
              &.payment_completed {
                background-color: #54B468;
              }
            }
          }

          .bgtype {
            span {
              border-radius: 4px;
              font-size: 0.7143rem;
              color: #27353C;
              padding: 3px 8px;

              &.RETAIL {
                background: #FDE6D5;
              }

              &.RWA {
                background: #FFFBC8;
              }

              &.ITOFFC {
                background: #FFEFD1;
              }

              &.HOTEL {
                background: #F9D5DC;
              }

              &.EDU {
                background: #F8CADF;
              }

              &.HOSPTL {
                background: #EFD1E8;
              }

              &.FACLTY {
                background: #DEDBEF;
              }

              &.MANU {
                background: #CDDFF9;
              }

              &.KABADI {
                background: #B2DDE3;
              }

              &.OTHER {
                background: #BCE2FE;
              }

              &.INDVL {
                background: #FDE6D5;
              }

              &.AGGR {
                background: #C8EFE9;
              }

              &.RAGPICK {
                background: #FDE6D5;
              }

              &.RECLR {
                background: #CCEAF3;
              }

              &.WHLR {
                background: #FDE6D5;
              }
            }

          }
        }
      }

      .ui-table .ui-table-tbody > tr > td {
        border: none !important;
      }
    }

    .ui-table-scrollable-header {
      overflow: visible;
    }
  }

}
