// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #328E69;
  --ion-color-primary-rgb: 50, 142, 105;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2c7d5c;
  --ion-color-primary-tint: #479978;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #FCD974;
  --ion-color-warning-rgb: 252,217,116;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #debf66;
  --ion-color-warning-tint: #fcdd82;

  /** danger **/
  --ion-color-danger: #D85757;
  --ion-color-danger-rgb: 216,87,87;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #be4d4d;
  --ion-color-danger-tint: #dc6868;

  /** dark **/
  --ion-color-dark: #27353C;
  --ion-color-dark-rgb: 39, 53, 60;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #222f35;
  --ion-color-dark-tint: #3d4950;

  --ion-color-medium: #5B7583;
  --ion-color-medium-rgb: 91,117,131;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #506773;
  --ion-color-medium-tint: #6b838f;

  --ion-color-light: #D6D6D6;
  --ion-color-light-rgb: 214,214,214;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #bcbcbc;
  --ion-color-light-tint: #dadada;


  ion-toolbar{
    --min-height: 70px;
  }

  .ion-color-gray{
    --ion-color-base: #7A7E81;
    --ion-color-base-rgb: 214,214,214;
    --ion-color-base-contrast: #000000;
    --ion-color-base-contrast-rgb: 0,0,0;
    --ion-color-base-shade: #bcbcbc;
    --ion-color-base-tint: #dadada;
  }


  .ion-color-white{
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255,255,255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;
  }


  /*global font sizes*/
  --ion-x-small-font-size: 0.7143rem;
  --ion-small-font-size: 0.8571rem;
  --ion-regular-font-size: 1rem;
  --ion-medium-font-size: 1.143rem;
  --ion-large-font-size: 1.714rem;
  --ion-largest-font-size: 62.91px;

  /*input font size*/
  --ion-input-font-size: var(--ion-medium-font-size, 17px);

/*
8px = 0.5714rem
9px = 0.6429rem
10px = 0.7143rem
11px = 0.7857rem
12px = 0.8571rem
13px = 0.9286rem
14px = 1rem (base)
15px = 1.071rem
16px = 1.143rem
18px = 1.286rem
20px = 1.429rem
22px = 1.571rem
24px = 1.714rem
26px = 1.857rem
28px = 2rem
30px = 2.143rem
32px = 2.286rem
34px = 2.429rem
36px = 2.571rem
38px = 2.714rem
40px = 2.857rem*/
  /*global headings*/
  --heading-xx-large-font-size: 55.91px;
  --heading-x-large-font-size:  3.429rem;
  --heading-large-font-size: 2rem;
  --heading-medium-font-size: 1.714rem;
  --heading-small-font-size: 20px;
  --heading-x-small-font-size: 17px;
  --heading-xx-small-font-size: 16px;

  /* icon image sizes*/
  --icon-small-size: 34px;
  --icon-medium-size: 44px;
  --icon-large-size: 110px;

  --page-bg: #F2F2F2;
}
