
.recykal {

  background: var(--ion-color-primary-contrast);

  *[font-regular] {
    font-family: 'Font Regular';
  }

  *[font-bold] {
    font-family: 'Font Bold';
  }

  *[font-medium] {
    font-family: 'Font Bold';
  }

  *[font-roboto] {
    font-family: var(--ion-default-font);
  }

  *[text-x-small] {
    font-size: var(--ion-x-small-font-size);
    line-height: 12px;
  }

  *[text-small] {
    font-size: var(--ion-small-font-size);
    line-height: 14px;
  }

  *[text-regular] {
    font-size: var(--ion-regular-font-size) !important;
    line-height: 18px;
  }

  *[text-medium] {
    font-size: var(--ion-medium-font-size);
  }

  *[text-large] {
    font-size: var(--ion-large-font-size);
  }

  *[text-largest] {
    font-size: var(--ion-largest-font-size);
  }

  *[input-font-size] {
    font-size: var(--ion-input-font-size);
  }

  [full-width] {
    width: 100% !important;
  }

  *[color-primary] {
    color: var(--ion-color-primary);
  }

  *[color-medium] {
    color: var(--ion-color-medium);
  }

  *[color-light] {
    color: var(--ion-color-light);
  }

  *[color-danger]{
    color: var(--ion-color-danger);
  }

  *[color-dark] {
    color: var(--ion-color-dark);
  }

  *[background-primary] {
    background: var(--ion-color-primary);;
  }

  *[background-dark] {
    background: var(--ion-color-dark);
  }

  *[background-white] {
    background: var(--ion-color-primary-contrast);
    --background: var(--ion-color-primary-contrast);
  }


  *[letter-space-2] {
    letter-spacing: 2px;
  }

  *[letter-space-1] {
    letter-spacing: 1px;
  }

  *[no-padding-right] {
    padding-right: 0;
  }

  *[no-padding-left] {
    padding-left: 0;
  }

  *[no-padding-top] {
    padding-top: 0;
    --padding-top: 0;
  }

  *[no-padding-bottom] {
    padding-bottom: 0;
    --padding-bottom: 0;
  }

  *[padding-left-10] {
    padding-left: 10px;
    --padding-left: 10px;
  }

  *[padding-right-10] {
    padding-right: 10px;
    --padding-right: 10px;
  }

  *[text-ellipsis] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }


  *[bg-transperant] {
    --background: transperant;
    background: transparent;
  }

  *[img-center] {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  *[margin-bottom-30] {
    margin-bottom: 30px;
  }

  *[margin-right-5] {
    margin-right: 5px;
  }

  *[margin-top-5] {
    margin-right: 5px;
  }

  *[margin-bottom-10] {
    margin-bottom: 10px !important;
    --margin-bottom: 10px;
  }

  *[margin-0-auto] {
    margin: 0 auto;
  }

  *[padding-5] {
    padding: 5px;
  }

  *[padding-right-5] {
    padding-right: 5px;
  }

  *[padding-left-5] {
    padding-left: 5px;
  }

  *[padding-top-10] {
    padding-top: 10px;
    --padding-top: 10px;
  }

  *[padding-bottom-10] {
    padding-bottom: 10px;
    --padding-bottom: 10px;
  }

  *[padding-10] {
    padding: 10px;
  }

  ion-item[no-highlight] {
    --highlight-height: 0;
  }

  ion-item[my-item] {
    --background: #ffffff;
    --border-radius: 10px;
  }

  *[unbold] {
    font-weight: normal !important;
  }

  ion-header[no-shodow]:after {
    background: none;
  }


  /*  --heading-xx-large-font-size: 62.91px;
    --heading-x-large-font-size: 49px;
    --heading-large-font-size: 28px;
    --heading-medium-font-size: 24px;
    --heading-small-font-size: 20px;
    --heading-x-small-font-size: 17px;
    --heading-xx-small-font-size: 16px;*/

  .heading-xx-large {
    margin-top: 72px;
    font-size: var(--heading-xx-large-font-size);
    font-weight: 600;
    background: -webkit-linear-gradient(269deg, var(--ion-color-primary), #63AD85);
    //background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: 9.82px;
  }

  *[no-margin] {
    margin: 0px !important;
    --margin: 0px !important;
  }

  *[no-margin-left] {
    margin-left: 0px !important;
  }

  *[no-margin-bottom] {
    margin-bottom: 0px !important;
    --margin-bottom: 0px !important;
  }

  *[no-margin-top] {
    margin-top: 0px !important;
    --margin-top: 0px !important;
  }


  .heading-x-large {

  }

  .heading-large {
    font-size: var(--heading-large-font-size);
    font-family: 'Font Bold';
    line-height: 34px;
  }

  .heading-medium {
    font-size: var(--heading-medium-font-size);
    font-family: 'Font Bold';
  }

  .heading-small {
    font-size: var(--heading-small-font-size);
    font-family: 'Font Bold';
    line-height: 23px;
  }

  .heading-x-small {
    font-size: var(--heading-x-small-font-size);
    font-family: 'Font Bold';
  }

  .heading-xx-small {
    font-size: var(--heading-xx-small-font-size);
    font-family: 'Font Bold';
  }

  .heading-italic {
    font-size: 1.071rem;
    font-weight: 600;
    color: #5B7583;
    font-style: italic;
  }

  .blurred-para {
    color: #A7A7A7;
    font-size: var(--ion-regular-font-size);
    font-family: 'Font Bold';
  }

  .custom-title {
    display: flex !important;
    align-items: center !important;

    ion-icon {
      padding-right: 10px !important;
      font-size: 26px !important;
    }

    &.with-backbtn {
      justify-content: center;
      margin-right: 65px;
    }
  }

  .custom-title-long {
    white-space: normal !important;

    ion-icon {
      font-size: 20px !important;
    }

    &.with-backbtn {
      justify-content: center;
      margin-right: 10vw;
      padding-bottom: 4px;
    }
  }

  ion-label {
    font-size: var(--ion-regular-font-size) !important;
    text-align: center;
    --color: #7A7E81;
    color: #7A7E81;
  }

  ion-item {
    --background-focused: none;

    ion-label {
      font-size: var(--ion-regular-font-size);
      text-align: left;
      --color: #7A7E81;
      color: #7A7E81;

      &.label-stacked.sc-ion-label-md-h {
        -webkit-transform: translate3d(0, 50%, 0) scale(1);
        transform: translate3d(0, 50%, 0) scale(1);
        padding-left: 5px;
      }
    }
  }

  ion-item.custom-input-item {
    --inner-padding-end: 0;
    --inner-padding-start: 0;
    --padding-start: 0;
    --highlight-height: 0;
  }

  *[d-flex] {
    display: flex;
  }

  ion-textarea, ion-input, ion-select {
    --background: #F2F2F2;
    --padding-start: 13px !important;
    --padding-bottom: 17px !important;
    --padding-top: 17px !important;
    --padding-end: 13px !important;
    --color: #27353C;
    border-radius: 10px;
    margin: 15px 0px 0px;
    font-size: var(--ion-input-font-size);
    text-align: left;
    font-family: 'Font Bold';
    height: 52px;

    .native-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: "Font Regular" !important;
    }

    .native-input::-moz-placeholder { /* Firefox 19+ */
      font-family: "Font Regular" !important;
    }

    .native-input:-ms-input-placeholder { /* IE 10+ */
      font-family: "Font Regular" !important;
    }

    .native-input:-moz-placeholder { /* Firefox 18- */
      font-family: "Font Regular" !important;
    }

    ion-icon {
      color: var(--ion-color-dark-tint);
      font-size: 24px;
      pointer-events: all;
      background: #F2F2F2;
      right: 6px;
      position: absolute;
      padding: 3px;
    }

    ion-text {
      pointer-events: all;
      right: 6px;
      background: inherit;
      position: absolute;
      padding: 3px;
    }

    ion-text.text-left {
      left: 0;
      position: relative;
      padding: 0 5px 0 8px;
    }
  }

  ion-select {
    --padding-bottom: 15px !important;
    background: #F2F2F2;
    color: #27353C;
  }

  ion-textarea {
    min-height: 52px;
    height: auto;
    font-size: var(--ion-regular-font-size);
    line-height: 18px;

    .native-textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: "Font Regular" !important;
    }

    .native-textarea::-moz-placeholder { /* Firefox 19+ */
      font-family: "Font Regular" !important;
    }

    .native-textarea:-ms-input-placeholder { /* IE 10+ */
      font-family: "Font Regular" !important;
    }

    .native-textarea:-moz-placeholder { /* Firefox 18- */
      font-family: "Font Regular" !important;
    }
  }

  ion-textarea.ion-invalid.ion-dirty {
    border: solid 1px var(--ion-color-danger);
  }

  ion-input.ion-invalid.ion-dirty {
    border: solid 1px var(--ion-color-danger);
  }

  ion-select.ion-invalid.ion-touched {
    border: solid 1px var(--ion-color-danger);
  }


  ion-button {
    font-size: 1.429rem;
    font-family: 'Font Bold';
    font-weight: normal;
    line-height: 20px;
    --color: var(--ion-color-primary-contrast);
    text-transform: none;
    width: 100%;
    max-height: 52px;
    --box-shadow: 2px 15px 14px -9px var(--ion-color-primary-tint);
    --border-radius: 10px 10px 10px 10px;
    margin-bottom: 15px;
    letter-spacing: 0;
  }

  /* ion-button.button-solid{
     --background: #328e69;
     background: linear-gradient(90deg,#328e69 100%, #63ad85 100%);
     background: -webkit-linear-gradient(90deg,#328e69 100%, #63ad85 100%);
     background: -moz-linear-gradient(90deg,#328e69 100%, #63ad85 100%);
   }*/

  .button-small {
    width: auto;
    font-size: 0.8571rem;
    font-weight: bold;
    --box-shadow: none;
    --border-radius: 5px;
    line-height: 7px;
    margin-bottom: 5px;
  }

  *[button-no-shadow] {
    --box-shadow: none;
  }

  .white-button {
    --border-color: var(--ion-color-light);
    --border-width: 1px;
    font-size: 16px;
    line-height: 19px;
    --background: var(--ion-color-primary-contrast);
    --color: #363636;
    --background-activated: var(--ion-color-primary-contrast);
  }

  ion-fab-button.locate-button {
    width: 46px;
    height: 46px;
  }

  .page-back-icon {
    margin-left: 29.23px;
    margin-top: 20px;
  }

  .label-stacked {

  }

  *[page-bg-colored] {
    --background: var(--page-bg);
    background: var(--page-bg);

    ion-refresher {
      background: var(--page-bg);
    }
  }

  ion-content::-webkit-scrollbar {
    display: none;
  }

  *[card-like] {
    --border-radius: 10px;
    margin-bottom: 15.65px;
    --inner-padding-bottom: 15px;
    --inner-padding-end: 15px;
    --inner-padding-start: 15px;
    --inner-padding-top: 15px;
    border-radius: 10px;
  }

  ion-card {
    --background: var(--ion-color-primary-contrast);
    border-radius: 10px;

    ion-card-content {
      padding: 5px;
    }
  }

  .item-selected {
    background: var(--ion-color-dark) !important;
    --background: var(--ion-color-dark) !important;
    border-radius: 10px;
    --border-radius: 10px;
    color: var(--ion-color-primary-contrast) !important;
    --color: var(--ion-color-primary-contrast) !important;
    // --box-shadow: 2px 15px 14px -9px var(--ion-color-dark-shade);
    height: 100%;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;

    ion-text, ion-label, ion-icon {
      color: var(--ion-color-primary-contrast) !important;
      --color: var(--ion-color-primary-contrast) !important;

    }

    ion-icon[item-selected-icon] {
      position: absolute;
      right: -8px;
      top: -8px;
      font-size: 24px;
      background: var(--ion-color-primary-contrast) !important;
      border-radius: 15px;
      display: block !important;

    }

    .radio-checked {
      width: 23px;
      padding: 2px;
      background: var(--ion-color-primary);
      border-radius: 11px;
      --color-checked: var(--ion-color-primary-contrast);
      float: right;
      margin-right: -15px;
      margin-top: -12px;
    }

  }

  .item-selectable {

  }

  .item-radio-selected {
    background: var(--ion-color-dark) !important;
    --background: var(--ion-color-dark) !important;
    border-radius: 5px;
    --border-radius: 5px;
    color: var(--ion-color-primary-contrast) !important;
    --color: var(--ion-color-primary-contrast) !important;
    --box-shadow: 2px 15px 14px -9px var(--ion-color-dark-shade);
    height: 100%;


    ion-text, ion-label, ion-icon {
      color: var(--ion-color-primary-contrast) !important;
      --color: var(--ion-color-primary-contrast) !important;

    }


    .radio-checked {
      width: 23px;
      padding: 2px;
      background: #63AD85;
      border-radius: 11px;
      --color-checked: var(--ion-color-primary-contrast);
      float: right;
      margin-right: -15px;
      margin-top: -12px;
    }
  }

  .item-radio-selectable {
    padding-bottom: 10px;

    ion-radio {
      float: right;
      margin-right: -15px;
      margin-top: -12px;
    }

    .input-radio-custom {
      float: right;
      margin-right: 9px;
      margin-top: -13px;
    }
  }

  .ion-radio-custom {

    .radio-checked {
      color: var(--ion-color-primary-contrast) !important;
      --color: var(--ion-color-primary-contrast) !important;
      width: 23px;
      padding: 2px;
      background: #63AD85;
      border-radius: 11px;
      --color-checked: var(--ion-color-primary-contrast);
    }

    ion-radio {
      color: var(--ion-color-primary-contrast) !important;
      --color: var(--ion-color-primary-contrast) !important;
      width: 23px;
      padding: 2px;
      background: #E5EAED;
      border-radius: 11px;
      --color-checked: var(--ion-color-primary-contrast);

      .radio-icon {
        .radio-inner {
          transform: rotate(42deg);
          border-width: 2px;
          border-top-width: 0;
          border-left-width: 0;
          border-style: solid;
          border-color: var(--color-checked);
        }
      }
    }


  }

  // make radio button to look like checkbox
  .input-radio-custom {
    display: block;
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    /*.container:hover input ~ .check-mark {
      background-color: #ccc;
    }*/

    /* When the radio button is checked */
    input:checked ~ .check-mark {
      background-color: #63AD85;
      -webkit-transition: 0.2s linear;
      -moz-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .check-mark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .check-mark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .check-mark:after {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-width: 2px;
      border-top-width: 0;
      border-left-width: 0;
      border-style: solid;
      border-color: var(--ion-color-primary-contrast);
      width: 25%;
      height: 50%;
      float: right;
      margin-left: 8px;
      margin-top: 3px;
      -webkit-transition: 0.2s linear;
      -moz-transition: 0.2s linear;
      -o-transition: 0.2s linear;
      transition: 0.2s linear;
    }
  }


  /*  .bottom-sheet {
      ion-modal.manager-sheet ion-backdrop {
        visibility: visible !important;
        z-index: 0;
      }

      .modal-wrapper {
        bottom: 0;
        width: 100%;
        max-width: 500px;
        min-height: 398px;
        position: absolute;
        border-radius: 20px 20px 0px 0px !important;
        height: auto;
      }

      .manager-sheet-content {
        background: #fff;
        width: 100%;
        height: 350px;
        padding: 20px;
      }

      .search-bar {
        margin: 0px;
        background: white;
        height: 57px;
        border-radius: inherit;

        ::placeholder {
          font-size: var(--ion-small-font-size);
          font-weight: normal;
          line-height: 18px;
        }

        ion-icon {
          color: #8D9093;
        }
      }
    }*/

  .bottom-sheet {
    ion-header {
      &:after {
        background-image: none;
      }
    }

    ion-toolbar {
      --min-height: 80px;

      ion-title {
        line-height: 28px;
        font-weight: initial;
        padding: 0 25px;

        .heading {
          text-align: center;
          font-family: font-bold;
          line-height: 20px;
          //letter-spacing: 2.8px;
          font-size: var(--ion-medium-font-size);
        }

        .sub-heading {
          font-size: var(--ion-medium-font-size);
          font-family: font-regular;
          line-height: 20px;
          color: var(--ion-color-medium);
        }
      }

    }

    ion-modal.manager-sheet ion-backdrop {
      visibility: visible !important;
      z-index: 0;
    }

    .dragger {
      border: 3px solid #D6D6D6;
      border-radius: 5px;
      margin: 16px auto;
      width: 82px;
    }

    .inner-content {

    }


    .modal-wrapper {
      bottom: 0;
      border-radius: 22px 22px 0 0;
      position: absolute;
      -moz-transition: height 1s ease;
      -webkit-transition: height 1s ease;
      -o-transition: height 1s ease;
      transition: height 1s ease;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        min-height: 200px;
        overflow: auto;
        padding: 15px;
      }
    }

    .manager-sheet-content {
      background: #fff;
      width: 100%;
      height: 350px;
      padding: 20px;
    }
  }


  .bottom-sheet-large {
    .modal-wrapper {
      bottom: 0;
      width: 100%;
      max-width: 500px;
      min-height: 95%;
      position: absolute;
      border-radius: 20px 20px 0px 0px !important;
      height: auto;
    }
  }

  .modal-page {
    padding-top: 30px;

    .modal-wrapper {
      border-radius: 20px 20px 0 0;
    }

    ion-backdrop {
      opacity: 0.8 !important;
    }
  }


  .ngx-smart-dialog-no-close {
    .nsm-dialog-btn-close {
      display: none;
    }
  }

  .ngx-smart-dialog {
    margin-top: 104px;

    .nsm-dialog-open {
      margin-top: 104px;
      width: 100%;
    }

    .nsm-content {
      border-radius: 10px;
    }

    .nsm-dialog-btn-close {
      left: 8px !important;
      right: initial !important;
    }
  }


  .ngx-smart-dialog-full {
    .nsm-dialog-open {
      width: 100%;
    }

    .nsm-content {
      border-radius: 10px;
      padding: 5px;
    }

    .nsm-dialog-btn-close {
      left: 8px !important;
      right: initial;
    }
  }

  .modal-auto-center {
    .modal-wrapper {
      --min-height: 55%;
      --width: 90%;
      --height: auto;
      border-radius: 10px;

      .ion-page {
        display: unset;
        position: relative;
      }
    }
  }

  .modal-auto-center.small {
    .modal-wrapper {
      --min-height: 40%;
    }
  }

  .custom-scrollable-modal {

    padding: 15px;

    .modal-wrapper {
      border-radius: 10px;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 15px;
      }
    }
  }


  .contrast-card {
    border-radius: 10px;

    ion-card-header {
      background: var(--ion-color-primary-contrast);
      padding-bottom: 0px;
    }

    ion-card-content {
      background: var(--ion-color-light-contrast);
      color: var(--ion-color-primary-contrast);
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }

  *[border-top] {
    border-top: 1px;
  }

  *[border-top-2] {
    border-top: 2px;
  }

  *[no-border] {
    border-width: 0px !important;
    --border-width: 0px !important;
  }

  :focus {
    outline: 0;
  }

  *[link] {
    cursor: pointer;
  }

  ion-back-button {
    --color: var(--ion-color-dark);
  }

  ion-icon[size-large] {
    font-size: var(--icon-large-size);
  }

  ion-icon[size-small] {
    font-size: var(--icon-small-size);
  }

  ion-icon[size-medium] {
    font-size: var(--icon-medium-size);
  }

  *[input-white] {
    ion-input {
      --background: var(--ion-color-primary-contrast);
      background: var(--ion-color-primary-contrast);
    }

    ion-textarea {
      --background: var(--ion-color-primary-contrast);
      background: var(--ion-color-primary-contrast);
    }
  }

  .padding-left-right-10 {
    padding-left: 10px;
    padding-right: 10px;
  }

  *[italic] {
    font-style: italic;
  }

  *[margin-10] {
    margin: 10px;
  }

  .custom-toast {
    --min-height: 50px;
    --width: 100%;
    --start: 0px;
    top: -12px;
  }

  .no-padding-all {
    --padding-start: 0px;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
    padding: 0;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }

  .custom-form-labels {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .custom-form-labels {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  *[border-radius-5] {
    --border-radius: 5px !important;
    border-radius: 5px !important;
  }

  *[border-radius-10] {
    border-radius: 10px !important;
    --border-radius: 10px !important;
  }

  *[no-box-shadow] {
    --box-shadow: none !important;
    box-shadow: none !important;
  }

  .blured {
    .modal-wrapper {
      background: transparent !important;
    }

    ion-backdrop {
      opacity: 0.95;
      background-color: #f1f1f1;
    }
  }

  *[modal-heading] {
    font-size: 1.429rem;
    line-height: 24px;
    font-family: 'Font Bold';
  }

  *[dashed-border] {
    border-style: dashed;
    --border-style: dashed;
  }

  input-errors {
    font-size: var(--ion-x-small-font-size);
    color: var(--ion-color-danger);
    text-align: left;
    width: 100%;
    padding-left: 5px;
    padding-top: 2px;
  }

  *[input-errors-custom] {
    font-size: var(--ion-x-small-font-size);
    color: var(--ion-color-danger);
    text-align: left;
    width: 100%;
  }

  *[display-block] {
    display: block;
  }

  ion-content {
    --offset-top: 1px !important;
  }

  .bottom-img {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 0;
    transform-origin: bottom;
    -webkit-transform-origin: bottom;
  }

  .title-center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .scroll-content {
    padding-bottom: 0 !important;
  }

  .page-content-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    margin-bottom: 0;
  }

  .offline {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;

    .center-div {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      left: 0;
      height: 40vh;
      width: 100%;

      ion-img {
        width: 20vh;
      }
    }
  }

  *[inner-padding-10] {
    --inner-padding-bottom: 10px;
    --inner-padding-end: 10px;
    --inner-padding-start: 10px;
    --inner-padding-top: 10px;
  }

  .custom-ion-segment {
    ion-segment-button {
      --padding-start: 0;
      --padding-end: 0;
      font-size: 1rem !important;
      line-height: 17px !important;
      --color: var(--ion-color-dark);
      font-family: 'Font Regular';
      --color-checked: var(--ion-color-primary);

      ::ng-deep {
        .segment-button-indicator {
          margin-top: -20px;
        }
      }
    }

    .segment-button-checked {
      font-family: 'Font Bold';
    }
  }

  [d-flex] {
    display: flex !important;
  }

  .custom-odometer {
    .odometer-theme-train-station {
      .odometer-digit {
        border-radius: 4px;
        background: var(--ion-color-primary-contrast);
        background: var(--ion-color-primary-contrast);
        font-family: 'Font Bold';
        font-size: 1.429rem;
        line-height: 25px;
        text-align: center;
        color: var(--ion-color-dark);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

        margin-right: 4px;
        margin-left: 4px;
        width: 28px;
        height: 50px;

        .odometer-digit-inner {
          padding: 6px;
          padding-top: 12px;

          .odometer-value {
            min-height: 6vh;
          }
        }
      }
    }
  }

  .aggregator {
    .odometer-theme-train-station {
      padding-top: 9px;

      ::ng-deep {
        .odometer-digit {
          border-radius: 4px;
          background: var(--ion-color-primary);
          font-family: 'Font Bold';
          font-size: 1.429rem;
          line-height: 25px;
          text-align: center;
          color: var(--ion-color-primary-contrast);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

          margin-right: 4px;
          margin-left: 4px;
          width: 28px;
          height: 50px;

          .odometer-digit-inner {
            padding: 6px;
            padding-top: 12px;
          }
        }
      }
    }
  }

  .display-flex-rule {
    display: flex;
    align-items: center;
    height: 100%;
  }

  //$margintop:calc( 1812 / 568 )*10%;
  .display-flex-rule-login {
    //display: flex;
    //align-items: center;
    //margin-top: calc((812px / 560) * 10%);
  }


  img, ion-img {
    text-indent: -10000px
  }

  .container-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ion-toolbar {
    ion-searchbar {
      .searchbar-input {
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-padding-start: 0;
        padding-inline-start: 0;
      }
    }
  }

  .border-danger {
    border: solid 1px var(--ion-color-danger);
  }

  .border-right-light {
    border-right: solid 1px var(--ion-color-light);
  }

  /* fieldset with heading only */
  fieldset.heading-fieldset {
    padding: 0;
    border-color: var(--ion-color-light);
    border-style: solid;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    border-top-width: 2px;

    legend {
      padding: 0 15px;
      text-align: center;
      color: var(--ion-color-dark);
    }
  }

  .rotate-45 {
    transform: rotate(45deg);
  }

  .rotate-270 {
    transform: rotate(270deg);
  }

}


// desktop css starts here
.recykal.recykal-desktop {
  .item-radio-selectable {
    margin: 5px 10px;
  }

  .lds-ripple {
    z-index: 0 !important;
  }

  .location-icon {
    z-index: 9;
  }

  .add-new-sec {
    background: #F4F9FA;
    margin: 5px 10px;
    min-height: 93%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    cursor: pointer;

    .add-new-icon-txt {
      line-height: 12px;

      ion-icon {
        height: 40px;
        width: 40px;
        color: #BAC4C9;
      }

      ion-text {
        display: block;
        font-size: 0.9231rem;
        color: #BAC4C9;
        font-family: 'Font Bold';
      }
    }
  }


  .sidebar {
    background: #f2f2f2;
    margin-top: 5px;

    .sidebar-content {
      margin: 10px 15px 15px 15px;
      height: 100%;
      background: #fff;
      border-radius: 6px;
    }

    ion-list {
      border-radius: 8px;
      height: 100%;
      position: relative;

      ion-item {
        color: #5B7583;
        font-size: 0.9231rem;
        cursor: pointer;
        img {
          margin-right: 10px;
          width: 16px;
          height: auto;
        }
      }

      .back-arrow-img {
        position: absolute;
        bottom: 15px;
        left: 50%;
        font-size: 24px;
        transform: translateX(-50%);
      }
    }
  }

  .outlet-content {
    main {
      margin-top: 10px;
    }
  }

  *[page-bg-colored] {
    --background: #ffffff;
    background: #ffffff;

    ion-refresher {
      background: #ffffff;
    }
  }


  .settings-desktop-screen {
    ion-tab-bar {
      justify-content: start;
      --background: #FFFFFF 0% 0% no-repeat padding-box;
      opacity: 1;
      --color: var(--ion-color-dark);
      --color-selected: var(--ion-color-primary);
      min-height: 9%;
      --border: 0px;

      ion-tab-button {
        --color-selected: var(--ion-color-primary);
        --color: var(--ion-color-medium);

        ion-label {
          font-size: 1.077rem !important;
          line-height: 20px;
          padding-bottom: 5px;
          display: inherit;
        }

        ion-icon[name='add-circle'] {
          font-size: 3.429rem;
        }
      }

      .tab-selected {
        ion-label {
          color: #27353C !important;
          border-bottom: 2px solid #27353C;
          font-family: Font Bold;
        }
      }
    }
  }

  form {
    .item-label {
      ion-input ,ion-textarea {
        font-size: 1.077rem;
        color: #27353C !important;
        background: #F4F9FA;
        border-radius: 6px;

        ion-icon {
          background: #f4f9fa !important;
        }
      }

      ion-label {
        color: #7A7E81;
        font-size: 0.9231rem;
      }
    }
  }

  .login-screen {
    .ion-input-button {
      --background: #F4F9FA;
    }

    form {
      height: 100%;
      display: flex;

      ion-row {
        height: 100%;

        .save-btn-section {
          display: flex;
          align-items: flex-end;

          ion-button {
            width: 372px;
            margin: 0 auto !important;
            display: block;
          }
        }
      }
    }

  }

  .verify-icon-margin {
    background: #F4F9FA;
  }

  .support-cards {
    background: #F4F9FA;
    border-radius: 6px;
    min-height: 114px;
    width: 194px;
    margin: 0 10px 10px 0;
    position: relative;

    ion-text, span {
      display: block;
    }

    span {
      font-size: 1rem;
      color: #27353C;
      margin-bottom: 8px;
      font-family: "Font Bold";
    }

    ion-text {
      font-size: 0.9231rem;
      color: #27353C;
    }

    ion-icon {
      position: absolute;
      top: -20px;
      font-size: 47px;
      left: 50%;
      transform: translateX(-50%);
    }

    .contact-txt {
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  .store-listing {
    .item-radio-selectable {
      background: #F4F9FA;
      height: 93%;
      margin: 5px 10px;
      cursor: pointer;
    }
    .item-radio-selected {
      height: 93% !important;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .ui-table-responsive {
    tr {
      .cursor-pointer {
        font-size: 12px;
        color: #27353C;
        font-family: Font Bold;
        cursor: pointer;
      }

      th, td {
        font-size: 0.9231rem !important;

        .address-box {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;

          &.line-1 {
            -webkit-line-clamp: 1;
          }

          &.line-2 {
            -webkit-line-clamp: 2;
          }

          &.line-3 {
            -webkit-line-clamp: 3;
          }
        }
      }

    }

    .status-display {
      .status-dot {
        display: none;
      }

      div:nth-child(2) {
        padding: 5px 10px;
        color: #fff;
        border-radius: 4px;
        font-size: 0.7692rem;
      }

      .created + div {
        background: #72abea;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .invoice_pending + div {
        background: #ffef8c;
        color:#000;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .cancelled + div {
        background: #f2503b;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .payment_pending + div {
        background: #fcd974;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .invoice_verification.pending + div {
        background: #fbc45b;
        color:#fff;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .payment_completed + div {
        background: #54b468;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .picked + div {
        background: #bee2c6;
        color:#000;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .scheduled + div {
        background: #ffa25f;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .received + div {
        background: #89cb97;
        text-transform: uppercase;
        font-family: Font Bold;
      }

      .on_hold + div {
        background: #ffd378;
        text-transform: uppercase;
        font-family: Font Bold;
      }
      .invoice_rejected + div {
        background: #ff7a5f;
        text-transform: uppercase;
        font-family: Font Bold;
        color:#fff;
      }
    }

    .ui-table-tbody {
      tr:nth-child(even) {
        background-color: #f9f9f9;
      }
    }

    input[type=text], input[type=search] {
      background-image: url('./assets/imgs/search-icon-grey.svg') !important;
      background-repeat: no-repeat !important;
      background-position: right !important;
      background-size: 20px !important;

      &:focus {
        background-image: none !important;
      }
    }

    input[type=search] {
      &::-webkit-search-cancel-button {
        -webkit-appearance: searchfield-cancel-button;
      }

      &:hover {
        background-image: none !important;
      }
    }

  }

  ion-button {
    font-size: inherit;
    font-family: inherit;
    font-weight: normal;
    line-height: 20px;
    text-transform: none;
    width: auto;
    max-height: 52px;
    --box-shadow: none;
    --border-radius: 10px 10px 10px 10px;
    margin-bottom: inherit;
    letter-spacing: 0;
  }

  ion-footer {
    ion-button {
      font-size: inherit;
      font-family: inherit;
      font-weight: normal;
      line-height: 20px;
      text-transform: none;
      width: 372px;
      margin: 0 auto !important;
      max-height: 52px;
      --box-shadow: 2px 15px 14px -9px var(--ion-color-primary-tint);
      --border-radius: 10px 10px 10px 10px;
      letter-spacing: 0;
      display: block;
    }
  }

  .view-btn {
    margin-bottom: 0;

    ion-icon {
      height: 16px;
      width: 16px;
      color: #5B7583;
    }
    &.proforma-downloaf-btn {
      --padding-end: 0;
      ion-icon {
        margin-left: 0;
      }
    }
  }

  .earning-summery {
    height: 100%;
    display: flex;
    align-items: center;
    //margin-top: 10px !important;
    background-size: 50% 50%;
    border-radius: 6px;
    background-repeat-x: repeat;
    &.summary-no-order {
      background-repeat-y: no-repeat;
      background-size: auto 45%;
      background-color: #438e69;
      background-position: bottom;
      min-height: 148px;
      display: flex;
      background-repeat-x: repeat;
      align-items: center;
      justify-content: center;
      background-image: url('./assets/imgs/desktop-footer-bg.png');
      .total-earning-section {
        width: 100%;
      }
    }
  }

  .upcoming-pickup {
    //margin-top: 10px !important;
    height: 100%;
    .desktop-pickup-time {
       *{
         text-align: left;
       }
    }
  }

  .store-addr {
    font-size: 0.8571rem !important;
  }

  .store-name {
    font-size: 1rem;
  }

  .card-top {
    .active, .inactive {
      font-size: var(--ion-x-small-font-size);
      font-family: 'Font Bold';
      letter-spacing: 1.5px;
    }
  }

  ion-list {
    ion-list-header {
      --background: #F4F9FA !important;
      cursor: pointer;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .display-flex-rule {
    justify-content: center;
  }


  .modal-page {
    .modal-wrapper {
      border-radius: 20px;
      position: absolute;
      bottom: unset;
    }
    &.desktop-verify-modal {
      .modal-wrapper {
        right:3%;
      }
    }
  }

  .desktop-pickup-btn {
    font-family: 'Font Bold';
    font-size: 1.385rem;
    margin-right: 20px;
  }

  .desktop-login-page {
    --background: transparent;

    .desktop-login-section {
      height: 100%;
      display: flex;
      align-items: center;

      .main-grid {
        background: rgba(153, 169, 178, 0);

        ion-row {
          width: 430px;
          margin: 0 auto;
        }

        .custom-input-item {
          --background: #dce3e7;

          .login-txt-upper-content {
            text-align: center;
            font-size: 1.429rem !important;
            margin-bottom: 30px;
            font-family: Font Regular;
            color: #27353C;
          }

          &.mobile {
            &img {
              width:20px;
            }
            ion-input {
              background-color: #fff;
              font-size: 1.692rem;
              font-family: Font Regular;
              --padding-top: 15px !important;
              ::-webkit-input-placeholder {
                font-size: 1rem;
                position: relative;
                top: -3px;
              }
              ion-text {
                font-size: 1.692rem;
                font-family: Font Regular;
                border: none;
                margin: -3px 10px 0 10px;
              }
            }
          }
          ion-input {
            background-color: #fff;
          }
        }

        .login-txt-non-register {
          .no-account-ques {
            text-align: center;
            font-size: 1.385rem;
            margin-bottom: 30px;
            font-family: Font Regular;
            margin-top: 20px;
            margin-bottom: 10px;
            display: block;
          }
          .use-email-ph {
            display: block;
            font-size: 1.286rem;
          }

        }

        .forgot-text {
          text-align: center;
          font-size: 1.385rem;
          margin-bottom: 30px;
        }
        .reset-text {
          text-align: center;
          font-size: 1.385rem;
          margin-bottom: 30px;
          color: var(--ion-color-primary);
        }


        .try-with-oemail {
          font-size: 1.231rem;
          span {
            color: var(--ion-color-primary);
          }
        }
      }
    }
  }

  .ion-radio-custom {
    display: flow-root;

    .desktop-product-item {
      width: 33%;
      float: left;
    }
  }

  .cart-product-screen {
    ion-item-sliding {
      border-radius: 0;
      width: inherit;
      display: inline;
    }

    .positive-cart-item, .negative-cart-item {
      width: 48%;
      --background: #F4F9FA;
      float: left;
      border-radius: 6px;
      margin: 0 1% 1% 1%;
/*      height: 100%;
      display: flex;*/
      ion-label {
        .product-name {
          font-size: 1rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .qty {
          font-size: 1rem;
        }
      }

      .desktop-cart-item-actions {
        .action-icon {
          color: #BAC4C9;
          font-size: 1.769rem;
          margin-left: 5px;
        }
      }
    }
  }

  .product-name {
    p:first-child {
      span {
        color: #5B7583;
        font-size: 1rem;
      }
    }
    p:last-child {
      span {
        font-size: 0.8571rem;
        font-family: Font Regular;
      }
    }
  }

  .desktop-summary-section {
    background: #F4F9FA;
    border-radius: 6px;
    margin-bottom: 10px;

    ion-row {

      .summary-section {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .edit-btn {
          margin-left: 10px;
          --background: #FAFAFA;
          --border-color: #dce3e7;
          font-size: 0.9231rem;
          line-height: 10px;
          letter-spacing: 1.5px;
          min-width: 61px;
          min-height: 26.38px;
          --color-activated: #465A65;
          color: #465A65;
          font-family: 'Font Bold';
          --border-radius: 6px;
          --border-width: 1px;
        }
      }
    }
  }

  .cart-item-list {
    .add-more-section {
      align-items: center;
      display: flex;
      justify-content: center;

      .add-more-btn {
        margin-left: 20px;
      }
    }
  }

  .date {
    height: 32px;

    div {
      div {
        padding-top: 4px !important;
      }
    }
  }

  .item-radio-selectable {
    margin: 0;
  }

  .ngx-smart-dialog-full {
    .nsm-dialog-open {
      width: 100%;
      max-width: 85%;
    }

    .nsm-dialog-btn-close {
      border-radius: 50%;
      left: unset !important;
      right: 10px;
      background: #dce3e7;
      width: 21px;
      height: 21px;

      svg {
        height: 11px;
        color: white;
        width: 9px;
      }
    }
  }

  .bottom-sheet {
    .modal-wrapper {
      width: 80%;
      height: 80%;
      border-radius: 10px;
      bottom: unset;
    }
    &.desktop-qty-modal {
      .modal-wrapper {
        top:60px;
        .ion-page {
          display: flex;
          height: 100%;
          .close-btn {
            margin-top: 10px;
            margin-right: 10px;
            ion-icon {
              cursor: pointer;
              color: #dce3e7;
              font-size: 21px;
              float:right;
            }
          }
          .inner-content {
            max-height: 100%;
            height: 100%;
            .pickup-flow {
              height: 100%;
              .pickup-flow-row {
                height: 100%;
              }
            }
            .content-header-section {
              padding-top: 0;
              padding-left: 15px;
              padding-bottom: 10px;
              .main-title {
                font-size: 1.286rem;
                font-family: Font Bold;
              }
              .sub-title {
                font-size: 1rem;
                color: #5B7583;
              }
            }
            .desktop-qty-main-section {
              background-color: #F4F9FA;
              border-radius: 6px;
              margin-left: 15px;
              min-height: 86%;

              .product-data-top {
                background-color: #F4F9FA;
                padding: 20px 20px 0 20px;
              }
              .product-data-bottom {
                background-color: #F4F9FA;
                padding: 20px 20px 20px 20px;
              }
            }

          }
        }
      }
    }
  }

  .custom-bottom-sheet {
    .modal-wrapper {
      bottom: unset;
      border-radius: 6px;
    }
    &.desktop-onboard-error {
      .modal-wrapper {
        bottom: unset;
        border-radius: 6px;
        height: 160px;
        width: 574px;
      }
    }
  }

  .transactions-screen {
    /*.all-transactions {
      height: 100% !important;
      margin: 0 5px;
    }*/
    .main-grid {
      height: 100% !important;
      margin: 0 5px;
    }
    .order-section {
      margin-top: -15px;
    }
    &.empty-desktop-orders {
      .order-section {
        margin-top: 0;
        height: 100%;
        .order-section-col {
          //border: 1px solid red;
        }
      }
    }
  }
  .payments-screen {
    .order-section {
      margin-top: -25px;
    }
  }

  .button-box-shadow {
    font-weight: normal !important;
    line-height: 20px !important;
    text-transform: none !important;
    width: 35% !important;
    max-height: 52px !important;
    --border-radius: 10px 10px 10px 10px !important;
    margin-bottom: inherit !important;
    letter-spacing: 0 !important;
    --box-shadow: 2px 15px 14px -9px var(--ion-color-primary-tint) !important;
  }

  .icon-size {
    width: 70px;
    height: 70px;
  }

  .margin-top-18 {
    margin-top: 18px;
  }

  .text-size {
    font-size: 1.429rem;
  }

  .background-color-col {
    background-color: #F4F9FA !important;
  }

  .apply-background1 {
    background: url('./assets/imgs/no-1.svg') no-repeat 100% 100% var(--ion-color-primary-contrast);
    background-position-y: 35%;
    background-position-x: 15%;
  }

  .apply-background2 {
    background: url('./assets/imgs/no-2.svg') no-repeat 100% 100% var(--ion-color-primary-contrast);
    background-position-y: 35%;
    background-position-x: 15%;
  }

  .apply-background3 {
    background: url('./assets/imgs/no-3.svg') no-repeat 100% 100% var(--ion-color-primary-contrast);
    background-position-y: 35%;
    background-position-x: 15%;
  }

  .apply-background4 {
    background: url('./assets/imgs/no-4.svg') no-repeat 100% 100% var(--ion-color-primary-contrast);
    background-position-y: 35%;
    background-position-x: 15%;
  }

  .apply-background5 {
    background: url('./assets/imgs/no-5.svg') no-repeat 100% 100% var(--ion-color-primary-contrast);
    background-position-y: 35%;
    background-position-x: 15%;
  }

  .margin-0 {
    margin: 0 !important;
  }

  .apply-background6 {
    background: url('./assets/imgs/no-6.svg') no-repeat 100% 100% var(--ion-color-primary-contrast);
    background-position-y: 35%;
    background-position-x: 15%;
  }

  .border-top-radius {
    background-color: #F4F9FA;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .border-bottom-radius {
    background-color: #F4F9FA;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-bottom: 30px;
  }

  .desktop-white-color {
    color: #FFFFFF !important;
    --color: #FFFFFF !important;
  }

  .minimise {
    .sidebar {
      width: 7vw !important;
      height: 85vh;
      position: relative;
      transition: 0.5s;
    }

    .outlet {
      max-width: 93vw;
      height: 85vh;
      flex: 1;
      position: relative;
      margin: 15px 15px 15px 0;

      .outlet-content {
        border-radius: 8px;
      }
    }

    ion-label {
      transition: 0.2s;
      opacity: 0;
      width: 0px;
      margin: 0 !important;
    }

    .back-arrow-img {
      ion-icon {
        margin-left: 0 !important;
        transform: rotate(180deg);
      }
    }
  }

  .desktop-header-options {
    ion-list {
      ion-item {
        --min-height: 40px;

        ion-label {
          cursor: pointer;
          color: #27353C;
          font-size: 1.077rem !important;
          text-align: center;
          font-family: Font Regular;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  #map_canvas {
    height: 100% !important;
    border-radius: 6px;
  }

  .add-store-form-container {
    ion-list {
      ion-list-header {
        background: #5B7583;
        border-radius: 6px !important;
        color: #fff;
      }
    }
  }

  .forgot-pwd-screen {
    --background: transparent;
  }

  .desktop-forgot-pwd-screen {
    --background: transparent;

    .desktop-forgot-pwd-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 0;
      margin: 0;
      ion-row {
        background: #dce3e7;
        width: 430px;
        margin: 0 auto;
        ion-list {
          background: #dce3e7;
          ion-item {
            --background: #dce3e7;
            &.custom-input-item {
              ion-label {
                color: #27353C;
                font-size: 1.077rem !important;
              }
              ion-input {
                background-color: #ffffff;
              }
            }
          }
        }
        .login-txt-upper-content {
          text-align: center;
          font-size: 1.429rem !important;
          margin-bottom: 30px;
          font-family: Font Regular;
          color: #27353C;
        }
      }
    }

  }
  .desktop-item-search {
    display: flex;
    align-items: center;
    justify-content: center;
    --min-height: 34px;
    border: 1px solid #5B7583;
    border-radius: 6px;
    height: 34px;
    --highlight-color-valid : #ffffff;
    ion-input {
      background: #ffffff;
      height: 34px;
      margin-top: 0;
    }
  }
  .desktop-forgot-pwd-sent-modal {
      .nsm-dialog-btn-close {
        right: 8px !important;
        left: unset !important;
      }
  }


  .kgs-recykal-platform {
    background-image: url('./assets/imgs/desktop-footer-bg.png');
    background-repeat-y: no-repeat;
    background-size: 500px 50%;
    background-color: #438e69;
    background-position: bottom;
    min-height: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-repeat-x:repeat;
    margin-top: 10px !important;
    ion-text, ion-icon , .odometer-formatting-mark{
      color:#ffffff;
    }
  }
  .bg-getting-started {
    .applyBackground1,.applyBackground2,.applyBackground3,.applyBackground4,.applyBackground5,.applyBackground6{
      margin: 20px auto;
    }
  }
  .quick-actions {
    ion-button {
      --border-radius: 6px;
      height: 29px;
      font-size: 12px;
    }
  }

  .ui-datepicker-calendar {
    tr:nth-child(2) {
      border-bottom: none !important;
    }
  }
  .desktop-footer-section {
    //box-shadow: 0px -2px 0px 0px #5b75830d;
    box-shadow: 0px -4px 2px 0px #5b75830d;
    padding-bottom: 0;
    padding-top: 10px;
    min-height: 70px;
    border-radius: 8px;
    &.with-padding{
      padding-bottom: 20px;
    }
    &.from-pickup-flow {
      position: fixed;
      bottom: 65px !important;
      width: 80%;
      border-radius: 0 0 10px 0;
      z-index: 15;
      left: 10%;
      padding-right: 15px;
    }
    &.from-manage-locations {
      position: fixed;
      bottom: 0 !important;
      background: white;
      border-radius: 0 0 10px 0;
      float: right;
      right: 0;
      padding-right: 15px;
      z-index: 15;
    }
    .next-step {
      padding: 0;
      ion-button {
        width: 274px;
        float: right;
        height: 46px;
        --border-radius: 6px;
        --background-color: #328E69;
        --box-shadow: none;
        opacity: 1;
        font-size: 1.286rem;
        font-family: Font Semi Bold;


        .next-icon {
          background: #fff;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          margin-left: 5px;
          ion-icon {
            vertical-align: middle;
            color:var(--ion-color-primary);
          }
        }
      }
      .float-unset {
        float: unset !important;
      }
    }
    .back-step {
      ion-button {
        width: 125px;
        float: left;
        height: 46px;
        --border-radius: 6px;
        --background: #F4F9FA;
        --background-activated: #F4F9FA;
        --background-focused: #F4F9FA;
        --background-hover: #F4F9FA;
        --box-shadow: none;
        color:#27353C;
        opacity: 1;
        font-size: 1.286rem;
        font-family: Font Semi Bold;
        .back-icon {
          background: #27353C;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          margin-right: 5px;
          ion-icon {
            vertical-align: middle;
            color: #fff;
          }
        }
      }
    }
  }

  .desktop-dashbaord {
    --padding-start: 0;
    --padding-end: 0;
    //--background: #f2f2f2;
    .data-section{
      padding: 0;
      background: linear-gradient(to bottom, #f2f2f2 0%,#f2f2f2 50%,#ffffff 100%);
      #ignore-in-report1 {
        padding-left: 0;
        margin-bottom: 10px;
      }
      #ignore-in-report2 {
        padding-right: 0;
        margin-bottom: 10px;
      }
      #total-report {
        padding: 0;
        //margin-top: 10px;
        .download-report-btn {
          position: absolute;
          z-index: 9;
          right: 0px;
          top: 0px;
          --background : #fff;
          --background-activated : #fff;
          --background-focused : #fff;
          --background-hover : #fff;
          --color : #000;
          --color-activated	: #000;
          --color-focused: #000;
          --color-hover : #000;
          font-size: 18px;
        }
      }
    }
  }
  input-errors , *[input-errors-custom] {
    font-size: 12px;
  }

  .support-screen {
    .content-header-section {
      padding-top: 0;
      padding-bottom: 25px;
      .main-title {
        font-size: 1.286rem;
        font-family: Font Bold;
      }
      .sub-title {
        font-size: 1rem;
        color: #5B7583;
      }
    }
  }
  .custom-odometer {
    .odometer-theme-train-station {
      .odometer-digit , .odometer-formatting-mark{
        display: inline-block;
      }
    }
  }
  .desktop-custom-title {
    font-family: "Font Bold";
    font-size: 1.385rem;
  }
  .add-edit-location-screen{
    .add-edit-form {
      &.from-manage-locations-grid {
        margin-bottom: 80px;
        .from-manage-locations-section {
          padding-top: 0;
          .status-toggle-col {
            position: fixed;
            top: 10px;
            right: 15px;
            z-index: 15;
            width: 40% !important;
          }
          .address-box {
            padding-top: 0;
            margin-top: -15px;
          }
        }
      }
    }
  }



}

// desktop css ends here
