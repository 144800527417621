@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~@swimlane/ngx-datatable/release/index.css';
@import '~@swimlane/ngx-datatable/release/assets/icons.css';
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/nova-light/theme.css";
@import "~primeng/resources/primeng.min.css";
body.dialog-open {
  overflow: hidden;
}

.nsm-dialog-btn-close {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 500ms;
  background-color: transparent;
  z-index: 999;
}
.overlay.nsm-overlay-open {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay.transparent {
  background-color: transparent;
}

.nsm-dialog {
  position: relative;
  opacity: 1;
  visibility: visible;
  min-height: 200px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  pointer-events: none;
  outline: none;
}
.nsm-dialog.nsm-dialog-close {
  opacity: 0;
}
.nsm-dialog.nsm-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (1.75rem * 2));
}

.nsm-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 2px;
  padding: 1rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  outline: 0;
  transform: translate3d(0, 0, 0);
}

.nsm-body {
  position: relative;
  flex: 1 1 auto;
}

/* *************************
* Animations
* *************************/
.nsm-dialog[class*=nsm-dialog-animation-] {
  transition: transform 500ms ease-in-out, opacity 500ms;
}

.nsm-dialog-animation-ltr {
  transform: translate3d(-50%, 0, 0);
}
.nsm-dialog-animation-ltr.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-ltr.nsm-dialog-close {
  transform: translate3d(-50%, 0, 0);
}

.nsm-dialog-animation-rtl {
  transform: translate3d(50%, 0, 0);
}
.nsm-dialog-animation-rtl.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-rtl.nsm-dialog-close {
  transform: translate3d(50%, 0, 0);
}

.nsm-dialog-animation-ttb {
  transform: translate3d(0, -50%, 0);
}
.nsm-dialog-animation-ttb.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-ttb.nsm-dialog-close {
  transform: translate3d(0, -50%, 0);
}

.nsm-dialog-animation-btt {
  transform: translate3d(0, 50%, 0);
}
.nsm-dialog-animation-btt.nsm-dialog-open {
  transform: translate3d(0, 0, 0);
}
.nsm-dialog-animation-btt.nsm-dialog-close {
  transform: translate3d(0, 50%, 0);
}

/*material table*/
.ngx-datatable.material {
  background: #FFF;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
   * Shared Styles
   */
  /**
   * Global Row Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304FFE;
  color: #FFF;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193AE4;
  color: #FFF;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041EF;
  color: #FFF;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304FFE;
  color: #FFF;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193AE4;
  color: #FFF;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041EF;
  color: #FFF;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #FFF;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #D9D8D9;
  border-top: solid 1px #D9D8D9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
/*ngprime table*/
.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 135;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #DE3E35;
  }
  50% {
    stroke: #F7C223;
  }
  75% {
    stroke: #1B9A59;
  }
  100% {
    stroke: #4285F4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 135;
  }
  50% {
    stroke-dashoffset: 33.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 135;
    transform: rotate(450deg);
  }
}
@font-face {
  font-family: "Font Regular";
  src: url("./assets/fonts/soleil-regular.ttf") format("truetype");
}
@font-face {
  font-family: "Font Light";
  src: url("./assets/fonts/soleil-light.ttf") format("truetype");
}
@font-face {
  font-family: "Font Bold";
  src: url("./assets/fonts/soleil-bold.ttf") format("truetype");
}
@font-face {
  font-family: "Font Semi Bold";
  src: url("./assets/fonts/soleil-semi-bold.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Regular";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
}
*[card-bg-img] {
  background-image: url("./assets/imgs/footer-bg-small.png");
}

:root {
  --ion-font-family: "Font Regular";
  font-size: 13px !important;
  --color: #27353C;
  color: #27353C;
}
:root .with-subtitle ion-toolbar {
  padding: 10px 0;
}
:root .with-subtitle ion-toolbar .subtitle {
  text-align: center;
  padding-top: 4px;
}
:root .with-subtitle ion-toolbar .subtitle.with-backbtn {
  margin-right: 65px;
}
:root .with-subtitle ion-toolbar .subtitle ion-text {
  line-height: 16px;
}
:root .app-logo {
  font-family: "Roboto Medium";
  margin-top: 0rem;
  text-align: center;
}
:root .app-logo.custom-title {
  margin-left: 6%;
  flex-direction: column;
}
:root .app-logo .title {
  font-family: "Font Bold";
  font-size: var(--heading-xx-large-font-size);
  font-weight: 600;
  letter-spacing: 1px;
  background: -webkit-linear-gradient(269deg, var(--ion-color-primary), #63AD85);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.2rem;
}
:root .app-logo .tagline {
  font-size: 0.875rem;
  font-weight: 600;
  color: #5B7583;
  font-style: italic;
}
:root .app-logo-in-header {
  font-family: "Roboto Medium";
}
:root .app-logo-in-header .title {
  font-family: "Roboto Bold";
  font-size: var(--heading-xx-large-font-size);
  font-weight: 600;
  letter-spacing: 1px;
  background: -webkit-linear-gradient(269deg, var(--ion-color-primary), #63AD85);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 0.2rem;
}
:root .app-logo-in-header .tagline {
  font-size: 0.875rem;
  font-weight: 600;
  color: #5B7583;
  font-style: italic;
}
:root ion-footer.with-shadow {
  box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.08);
}
:root ion-footer:before {
  background: 0;
}
:root ion-app {
  background-color: transparent !important;
}

.recykal {
  background: var(--ion-color-primary-contrast);
  /*  --heading-xx-large-font-size: 62.91px;
    --heading-x-large-font-size: 49px;
    --heading-large-font-size: 28px;
    --heading-medium-font-size: 24px;
    --heading-small-font-size: 20px;
    --heading-x-small-font-size: 17px;
    --heading-xx-small-font-size: 16px;*/
  /* ion-button.button-solid{
     --background: #328e69;
     background: linear-gradient(90deg,#328e69 100%, #63ad85 100%);
     background: -webkit-linear-gradient(90deg,#328e69 100%, #63ad85 100%);
     background: -moz-linear-gradient(90deg,#328e69 100%, #63ad85 100%);
   }*/
  /*  .bottom-sheet {
      ion-modal.manager-sheet ion-backdrop {
        visibility: visible !important;
        z-index: 0;
      }

      .modal-wrapper {
        bottom: 0;
        width: 100%;
        max-width: 500px;
        min-height: 398px;
        position: absolute;
        border-radius: 20px 20px 0px 0px !important;
        height: auto;
      }

      .manager-sheet-content {
        background: #fff;
        width: 100%;
        height: 350px;
        padding: 20px;
      }

      .search-bar {
        margin: 0px;
        background: white;
        height: 57px;
        border-radius: inherit;

        ::placeholder {
          font-size: var(--ion-small-font-size);
          font-weight: normal;
          line-height: 18px;
        }

        ion-icon {
          color: #8D9093;
        }
      }
    }*/
  /* fieldset with heading only */
}
.recykal *[font-regular] {
  font-family: "Font Regular";
}
.recykal *[font-bold] {
  font-family: "Font Bold";
}
.recykal *[font-medium] {
  font-family: "Font Bold";
}
.recykal *[font-roboto] {
  font-family: var(--ion-default-font);
}
.recykal *[text-x-small] {
  font-size: var(--ion-x-small-font-size);
  line-height: 12px;
}
.recykal *[text-small] {
  font-size: var(--ion-small-font-size);
  line-height: 14px;
}
.recykal *[text-regular] {
  font-size: var(--ion-regular-font-size) !important;
  line-height: 18px;
}
.recykal *[text-medium] {
  font-size: var(--ion-medium-font-size);
}
.recykal *[text-large] {
  font-size: var(--ion-large-font-size);
}
.recykal *[text-largest] {
  font-size: var(--ion-largest-font-size);
}
.recykal *[input-font-size] {
  font-size: var(--ion-input-font-size);
}
.recykal [full-width] {
  width: 100% !important;
}
.recykal *[color-primary] {
  color: var(--ion-color-primary);
}
.recykal *[color-medium] {
  color: var(--ion-color-medium);
}
.recykal *[color-light] {
  color: var(--ion-color-light);
}
.recykal *[color-danger] {
  color: var(--ion-color-danger);
}
.recykal *[color-dark] {
  color: var(--ion-color-dark);
}
.recykal *[background-primary] {
  background: var(--ion-color-primary);
}
.recykal *[background-dark] {
  background: var(--ion-color-dark);
}
.recykal *[background-white] {
  background: var(--ion-color-primary-contrast);
  --background: var(--ion-color-primary-contrast);
}
.recykal *[letter-space-2] {
  letter-spacing: 2px;
}
.recykal *[letter-space-1] {
  letter-spacing: 1px;
}
.recykal *[no-padding-right] {
  padding-right: 0;
}
.recykal *[no-padding-left] {
  padding-left: 0;
}
.recykal *[no-padding-top] {
  padding-top: 0;
  --padding-top: 0;
}
.recykal *[no-padding-bottom] {
  padding-bottom: 0;
  --padding-bottom: 0;
}
.recykal *[padding-left-10] {
  padding-left: 10px;
  --padding-left: 10px;
}
.recykal *[padding-right-10] {
  padding-right: 10px;
  --padding-right: 10px;
}
.recykal *[text-ellipsis] {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.recykal *[bg-transperant] {
  --background: transperant;
  background: transparent;
}
.recykal *[img-center] {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.recykal *[margin-bottom-30] {
  margin-bottom: 30px;
}
.recykal *[margin-right-5] {
  margin-right: 5px;
}
.recykal *[margin-top-5] {
  margin-right: 5px;
}
.recykal *[margin-bottom-10] {
  margin-bottom: 10px !important;
  --margin-bottom: 10px;
}
.recykal *[margin-0-auto] {
  margin: 0 auto;
}
.recykal *[padding-5] {
  padding: 5px;
}
.recykal *[padding-right-5] {
  padding-right: 5px;
}
.recykal *[padding-left-5] {
  padding-left: 5px;
}
.recykal *[padding-top-10] {
  padding-top: 10px;
  --padding-top: 10px;
}
.recykal *[padding-bottom-10] {
  padding-bottom: 10px;
  --padding-bottom: 10px;
}
.recykal *[padding-10] {
  padding: 10px;
}
.recykal ion-item[no-highlight] {
  --highlight-height: 0;
}
.recykal ion-item[my-item] {
  --background: #ffffff;
  --border-radius: 10px;
}
.recykal *[unbold] {
  font-weight: normal !important;
}
.recykal ion-header[no-shodow]:after {
  background: none;
}
.recykal .heading-xx-large {
  margin-top: 72px;
  font-size: var(--heading-xx-large-font-size);
  font-weight: 600;
  background: -webkit-linear-gradient(269deg, var(--ion-color-primary), #63AD85);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 9.82px;
}
.recykal *[no-margin] {
  margin: 0px !important;
  --margin: 0px !important;
}
.recykal *[no-margin-left] {
  margin-left: 0px !important;
}
.recykal *[no-margin-bottom] {
  margin-bottom: 0px !important;
  --margin-bottom: 0px !important;
}
.recykal *[no-margin-top] {
  margin-top: 0px !important;
  --margin-top: 0px !important;
}
.recykal .heading-large {
  font-size: var(--heading-large-font-size);
  font-family: "Font Bold";
  line-height: 34px;
}
.recykal .heading-medium {
  font-size: var(--heading-medium-font-size);
  font-family: "Font Bold";
}
.recykal .heading-small {
  font-size: var(--heading-small-font-size);
  font-family: "Font Bold";
  line-height: 23px;
}
.recykal .heading-x-small {
  font-size: var(--heading-x-small-font-size);
  font-family: "Font Bold";
}
.recykal .heading-xx-small {
  font-size: var(--heading-xx-small-font-size);
  font-family: "Font Bold";
}
.recykal .heading-italic {
  font-size: 1.071rem;
  font-weight: 600;
  color: #5B7583;
  font-style: italic;
}
.recykal .blurred-para {
  color: #A7A7A7;
  font-size: var(--ion-regular-font-size);
  font-family: "Font Bold";
}
.recykal .custom-title {
  display: flex !important;
  align-items: center !important;
}
.recykal .custom-title ion-icon {
  padding-right: 10px !important;
  font-size: 26px !important;
}
.recykal .custom-title.with-backbtn {
  justify-content: center;
  margin-right: 65px;
}
.recykal .custom-title-long {
  white-space: normal !important;
}
.recykal .custom-title-long ion-icon {
  font-size: 20px !important;
}
.recykal .custom-title-long.with-backbtn {
  justify-content: center;
  margin-right: 10vw;
  padding-bottom: 4px;
}
.recykal ion-label {
  font-size: var(--ion-regular-font-size) !important;
  text-align: center;
  --color: #7A7E81;
  color: #7A7E81;
}
.recykal ion-item {
  --background-focused: none;
}
.recykal ion-item ion-label {
  font-size: var(--ion-regular-font-size);
  text-align: left;
  --color: #7A7E81;
  color: #7A7E81;
}
.recykal ion-item ion-label.label-stacked.sc-ion-label-md-h {
  -webkit-transform: translate3d(0, 50%, 0) scale(1);
  transform: translate3d(0, 50%, 0) scale(1);
  padding-left: 5px;
}
.recykal ion-item.custom-input-item {
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-start: 0;
  --highlight-height: 0;
}
.recykal *[d-flex] {
  display: flex;
}
.recykal ion-textarea, .recykal ion-input, .recykal ion-select {
  --background: #F2F2F2;
  --padding-start: 13px !important;
  --padding-bottom: 17px !important;
  --padding-top: 17px !important;
  --padding-end: 13px !important;
  --color: #27353C;
  border-radius: 10px;
  margin: 15px 0px 0px;
  font-size: var(--ion-input-font-size);
  text-align: left;
  font-family: "Font Bold";
  height: 52px;
}
.recykal ion-textarea .native-input::-webkit-input-placeholder, .recykal ion-input .native-input::-webkit-input-placeholder, .recykal ion-select .native-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea .native-input::-moz-placeholder, .recykal ion-input .native-input::-moz-placeholder, .recykal ion-select .native-input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea .native-input:-ms-input-placeholder, .recykal ion-input .native-input:-ms-input-placeholder, .recykal ion-select .native-input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea .native-input:-moz-placeholder, .recykal ion-input .native-input:-moz-placeholder, .recykal ion-select .native-input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea ion-icon, .recykal ion-input ion-icon, .recykal ion-select ion-icon {
  color: var(--ion-color-dark-tint);
  font-size: 24px;
  pointer-events: all;
  background: #F2F2F2;
  right: 6px;
  position: absolute;
  padding: 3px;
}
.recykal ion-textarea ion-text, .recykal ion-input ion-text, .recykal ion-select ion-text {
  pointer-events: all;
  right: 6px;
  background: inherit;
  position: absolute;
  padding: 3px;
}
.recykal ion-textarea ion-text.text-left, .recykal ion-input ion-text.text-left, .recykal ion-select ion-text.text-left {
  left: 0;
  position: relative;
  padding: 0 5px 0 8px;
}
.recykal ion-select {
  --padding-bottom: 15px !important;
  background: #F2F2F2;
  color: #27353C;
}
.recykal ion-textarea {
  min-height: 52px;
  height: auto;
  font-size: var(--ion-regular-font-size);
  line-height: 18px;
}
.recykal ion-textarea .native-textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea .native-textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea .native-textarea:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea .native-textarea:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Font Regular" !important;
}
.recykal ion-textarea.ion-invalid.ion-dirty {
  border: solid 1px var(--ion-color-danger);
}
.recykal ion-input.ion-invalid.ion-dirty {
  border: solid 1px var(--ion-color-danger);
}
.recykal ion-select.ion-invalid.ion-touched {
  border: solid 1px var(--ion-color-danger);
}
.recykal ion-button {
  font-size: 1.429rem;
  font-family: "Font Bold";
  font-weight: normal;
  line-height: 20px;
  --color: var(--ion-color-primary-contrast);
  text-transform: none;
  width: 100%;
  max-height: 52px;
  --box-shadow: 2px 15px 14px -9px var(--ion-color-primary-tint);
  --border-radius: 10px 10px 10px 10px;
  margin-bottom: 15px;
  letter-spacing: 0;
}
.recykal .button-small {
  width: auto;
  font-size: 0.8571rem;
  font-weight: bold;
  --box-shadow: none;
  --border-radius: 5px;
  line-height: 7px;
  margin-bottom: 5px;
}
.recykal *[button-no-shadow] {
  --box-shadow: none;
}
.recykal .white-button {
  --border-color: var(--ion-color-light);
  --border-width: 1px;
  font-size: 16px;
  line-height: 19px;
  --background: var(--ion-color-primary-contrast);
  --color: #363636;
  --background-activated: var(--ion-color-primary-contrast);
}
.recykal ion-fab-button.locate-button {
  width: 46px;
  height: 46px;
}
.recykal .page-back-icon {
  margin-left: 29.23px;
  margin-top: 20px;
}
.recykal *[page-bg-colored] {
  --background: var(--page-bg);
  background: var(--page-bg);
}
.recykal *[page-bg-colored] ion-refresher {
  background: var(--page-bg);
}
.recykal ion-content::-webkit-scrollbar {
  display: none;
}
.recykal *[card-like] {
  --border-radius: 10px;
  margin-bottom: 15.65px;
  --inner-padding-bottom: 15px;
  --inner-padding-end: 15px;
  --inner-padding-start: 15px;
  --inner-padding-top: 15px;
  border-radius: 10px;
}
.recykal ion-card {
  --background: var(--ion-color-primary-contrast);
  border-radius: 10px;
}
.recykal ion-card ion-card-content {
  padding: 5px;
}
.recykal .item-selected {
  background: var(--ion-color-dark) !important;
  --background: var(--ion-color-dark) !important;
  border-radius: 10px;
  --border-radius: 10px;
  color: var(--ion-color-primary-contrast) !important;
  --color: var(--ion-color-primary-contrast) !important;
  height: 100%;
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
}
.recykal .item-selected ion-text, .recykal .item-selected ion-label, .recykal .item-selected ion-icon {
  color: var(--ion-color-primary-contrast) !important;
  --color: var(--ion-color-primary-contrast) !important;
}
.recykal .item-selected ion-icon[item-selected-icon] {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 24px;
  background: var(--ion-color-primary-contrast) !important;
  border-radius: 15px;
  display: block !important;
}
.recykal .item-selected .radio-checked {
  width: 23px;
  padding: 2px;
  background: var(--ion-color-primary);
  border-radius: 11px;
  --color-checked: var(--ion-color-primary-contrast);
  float: right;
  margin-right: -15px;
  margin-top: -12px;
}
.recykal .item-radio-selected {
  background: var(--ion-color-dark) !important;
  --background: var(--ion-color-dark) !important;
  border-radius: 5px;
  --border-radius: 5px;
  color: var(--ion-color-primary-contrast) !important;
  --color: var(--ion-color-primary-contrast) !important;
  --box-shadow: 2px 15px 14px -9px var(--ion-color-dark-shade);
  height: 100%;
}
.recykal .item-radio-selected ion-text, .recykal .item-radio-selected ion-label, .recykal .item-radio-selected ion-icon {
  color: var(--ion-color-primary-contrast) !important;
  --color: var(--ion-color-primary-contrast) !important;
}
.recykal .item-radio-selected .radio-checked {
  width: 23px;
  padding: 2px;
  background: #63AD85;
  border-radius: 11px;
  --color-checked: var(--ion-color-primary-contrast);
  float: right;
  margin-right: -15px;
  margin-top: -12px;
}
.recykal .item-radio-selectable {
  padding-bottom: 10px;
}
.recykal .item-radio-selectable ion-radio {
  float: right;
  margin-right: -15px;
  margin-top: -12px;
}
.recykal .item-radio-selectable .input-radio-custom {
  float: right;
  margin-right: 9px;
  margin-top: -13px;
}
.recykal .ion-radio-custom .radio-checked {
  color: var(--ion-color-primary-contrast) !important;
  --color: var(--ion-color-primary-contrast) !important;
  width: 23px;
  padding: 2px;
  background: #63AD85;
  border-radius: 11px;
  --color-checked: var(--ion-color-primary-contrast);
}
.recykal .ion-radio-custom ion-radio {
  color: var(--ion-color-primary-contrast) !important;
  --color: var(--ion-color-primary-contrast) !important;
  width: 23px;
  padding: 2px;
  background: #E5EAED;
  border-radius: 11px;
  --color-checked: var(--ion-color-primary-contrast);
}
.recykal .ion-radio-custom ion-radio .radio-icon .radio-inner {
  transform: rotate(42deg);
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--color-checked);
}
.recykal .input-radio-custom {
  display: block;
  position: relative;
  /* On mouse-over, add a grey background color */
  /*.container:hover input ~ .check-mark {
    background-color: #ccc;
  }*/
  /* When the radio button is checked */
  /* Create the indicator (the dot/circle - hidden when not checked) */
  /* Show the indicator (dot/circle) when checked */
  /* Style the indicator (dot/circle) */
}
.recykal .input-radio-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.recykal .input-radio-custom .check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border-radius: 50%;
}
.recykal .input-radio-custom input:checked ~ .check-mark {
  background-color: #63AD85;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}
.recykal .input-radio-custom .check-mark:after {
  content: "";
  position: absolute;
  display: none;
}
.recykal .input-radio-custom input:checked ~ .check-mark:after {
  display: block;
}
.recykal .input-radio-custom .check-mark:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: var(--ion-color-primary-contrast);
  width: 25%;
  height: 50%;
  float: right;
  margin-left: 8px;
  margin-top: 3px;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}
.recykal .bottom-sheet {
  --height: auto;
}
.recykal .bottom-sheet ion-header:after {
  background-image: none;
}
.recykal .bottom-sheet ion-toolbar {
  --min-height: 80px;
}
.recykal .bottom-sheet ion-toolbar ion-title {
  line-height: 28px;
  font-weight: initial;
  padding: 0 25px;
}
.recykal .bottom-sheet ion-toolbar ion-title .heading {
  text-align: center;
  font-family: font-bold;
  line-height: 20px;
  font-size: var(--ion-medium-font-size);
}
.recykal .bottom-sheet ion-toolbar ion-title .sub-heading {
  font-size: var(--ion-medium-font-size);
  font-family: font-regular;
  line-height: 20px;
  color: var(--ion-color-medium);
}
.recykal .bottom-sheet ion-modal.manager-sheet ion-backdrop {
  visibility: visible !important;
  z-index: 0;
}
.recykal .bottom-sheet .dragger {
  border: 3px solid #D6D6D6;
  border-radius: 5px;
  margin: 16px auto;
  width: 82px;
}
.recykal .bottom-sheet .modal-wrapper {
  bottom: 0;
  border-radius: 22px 22px 0 0;
  position: absolute;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
}
.recykal .bottom-sheet .ion-page {
  position: relative;
  display: block;
  contain: content;
}
.recykal .bottom-sheet .ion-page .inner-content {
  max-height: 80vh;
  min-height: 200px;
  overflow: auto;
  padding: 15px;
}
.recykal .bottom-sheet .manager-sheet-content {
  background: #fff;
  width: 100%;
  height: 350px;
  padding: 20px;
}
.recykal .bottom-sheet-large .modal-wrapper {
  bottom: 0;
  width: 100%;
  max-width: 500px;
  min-height: 95%;
  position: absolute;
  border-radius: 20px 20px 0px 0px !important;
  height: auto;
}
.recykal .modal-page {
  padding-top: 30px;
}
.recykal .modal-page .modal-wrapper {
  border-radius: 20px 20px 0 0;
}
.recykal .modal-page ion-backdrop {
  opacity: 0.8 !important;
}
.recykal .ngx-smart-dialog-no-close .nsm-dialog-btn-close {
  display: none;
}
.recykal .ngx-smart-dialog {
  margin-top: 104px;
}
.recykal .ngx-smart-dialog .nsm-dialog-open {
  margin-top: 104px;
  width: 100%;
}
.recykal .ngx-smart-dialog .nsm-content {
  border-radius: 10px;
}
.recykal .ngx-smart-dialog .nsm-dialog-btn-close {
  left: 8px !important;
  right: initial !important;
}
.recykal .ngx-smart-dialog-full .nsm-dialog-open {
  width: 100%;
}
.recykal .ngx-smart-dialog-full .nsm-content {
  border-radius: 10px;
  padding: 5px;
}
.recykal .ngx-smart-dialog-full .nsm-dialog-btn-close {
  left: 8px !important;
  right: initial;
}
.recykal .modal-auto-center .modal-wrapper {
  --min-height: 55%;
  --width: 90%;
  --height: auto;
  border-radius: 10px;
}
.recykal .modal-auto-center .modal-wrapper .ion-page {
  display: unset;
  position: relative;
}
.recykal .modal-auto-center.small .modal-wrapper {
  --min-height: 40%;
}
.recykal .custom-scrollable-modal {
  padding: 15px;
  --height: auto;
}
.recykal .custom-scrollable-modal .modal-wrapper {
  border-radius: 10px;
}
.recykal .custom-scrollable-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}
.recykal .custom-scrollable-modal .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
  padding: 15px;
}
.recykal .contrast-card {
  border-radius: 10px;
}
.recykal .contrast-card ion-card-header {
  background: var(--ion-color-primary-contrast);
  padding-bottom: 0px;
}
.recykal .contrast-card ion-card-content {
  background: var(--ion-color-light-contrast);
  color: var(--ion-color-primary-contrast);
  padding-top: 5px;
  padding-bottom: 10px;
}
.recykal *[border-top] {
  border-top: 1px;
}
.recykal *[border-top-2] {
  border-top: 2px;
}
.recykal *[no-border] {
  border-width: 0px !important;
  --border-width: 0px !important;
}
.recykal :focus {
  outline: 0;
}
.recykal *[link] {
  cursor: pointer;
}
.recykal ion-back-button {
  --color: var(--ion-color-dark);
}
.recykal ion-icon[size-large] {
  font-size: var(--icon-large-size);
}
.recykal ion-icon[size-small] {
  font-size: var(--icon-small-size);
}
.recykal ion-icon[size-medium] {
  font-size: var(--icon-medium-size);
}
.recykal *[input-white] ion-input {
  --background: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary-contrast);
}
.recykal *[input-white] ion-textarea {
  --background: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary-contrast);
}
.recykal .padding-left-right-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.recykal *[italic] {
  font-style: italic;
}
.recykal *[margin-10] {
  margin: 10px;
}
.recykal .custom-toast {
  --min-height: 50px;
  --width: 100%;
  --start: 0px;
  top: -12px;
}
.recykal .no-padding-all {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --inner-padding-start: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  padding: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-padding-end: 0;
  padding-inline-end: 0;
}
.recykal .custom-form-labels {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
}
.recykal .custom-form-labels {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
}
.recykal *[border-radius-5] {
  --border-radius: 5px !important;
  border-radius: 5px !important;
}
.recykal *[border-radius-10] {
  border-radius: 10px !important;
  --border-radius: 10px !important;
}
.recykal *[no-box-shadow] {
  --box-shadow: none !important;
  box-shadow: none !important;
}
.recykal .blured .modal-wrapper {
  background: transparent !important;
}
.recykal .blured ion-backdrop {
  opacity: 0.95;
  background-color: #f1f1f1;
}
.recykal *[modal-heading] {
  font-size: 1.429rem;
  line-height: 24px;
  font-family: "Font Bold";
}
.recykal *[dashed-border] {
  border-style: dashed;
  --border-style: dashed;
}
.recykal input-errors {
  font-size: var(--ion-x-small-font-size);
  color: var(--ion-color-danger);
  text-align: left;
  width: 100%;
  padding-left: 5px;
  padding-top: 2px;
}
.recykal *[input-errors-custom] {
  font-size: var(--ion-x-small-font-size);
  color: var(--ion-color-danger);
  text-align: left;
  width: 100%;
}
.recykal *[display-block] {
  display: block;
}
.recykal ion-content {
  --offset-top: 1px !important;
}
.recykal .bottom-img {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 0;
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
}
.recykal .title-center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.recykal .scroll-content {
  padding-bottom: 0 !important;
}
.recykal .page-content-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  margin-bottom: 0;
}
.recykal .offline {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}
.recykal .offline .center-div {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  left: 0;
  height: 40vh;
  width: 100%;
}
.recykal .offline .center-div ion-img {
  width: 20vh;
}
.recykal *[inner-padding-10] {
  --inner-padding-bottom: 10px;
  --inner-padding-end: 10px;
  --inner-padding-start: 10px;
  --inner-padding-top: 10px;
}
.recykal .custom-ion-segment ion-segment-button {
  --padding-start: 0;
  --padding-end: 0;
  font-size: 1rem !important;
  line-height: 17px !important;
  --color: var(--ion-color-dark);
  font-family: "Font Regular";
  --color-checked: var(--ion-color-primary);
}
.recykal .custom-ion-segment ion-segment-button ::ng-deep .segment-button-indicator {
  margin-top: -20px;
}
.recykal .custom-ion-segment .segment-button-checked {
  font-family: "Font Bold";
}
.recykal [d-flex] {
  display: flex !important;
}
.recykal .custom-odometer .odometer-theme-train-station .odometer-digit {
  border-radius: 4px;
  background: var(--ion-color-primary-contrast);
  background: var(--ion-color-primary-contrast);
  font-family: "Font Bold";
  font-size: 1.429rem;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-dark);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-right: 4px;
  margin-left: 4px;
  width: 28px;
  height: 50px;
}
.recykal .custom-odometer .odometer-theme-train-station .odometer-digit .odometer-digit-inner {
  padding: 6px;
  padding-top: 12px;
}
.recykal .custom-odometer .odometer-theme-train-station .odometer-digit .odometer-digit-inner .odometer-value {
  min-height: 6vh;
}
.recykal .aggregator .odometer-theme-train-station {
  padding-top: 9px;
}
.recykal .aggregator .odometer-theme-train-station ::ng-deep .odometer-digit {
  border-radius: 4px;
  background: var(--ion-color-primary);
  font-family: "Font Bold";
  font-size: 1.429rem;
  line-height: 25px;
  text-align: center;
  color: var(--ion-color-primary-contrast);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-right: 4px;
  margin-left: 4px;
  width: 28px;
  height: 50px;
}
.recykal .aggregator .odometer-theme-train-station ::ng-deep .odometer-digit .odometer-digit-inner {
  padding: 6px;
  padding-top: 12px;
}
.recykal .display-flex-rule {
  display: flex;
  align-items: center;
  height: 100%;
}
.recykal img, .recykal ion-img {
  text-indent: -10000px;
}
.recykal .container-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.recykal ion-toolbar ion-searchbar .searchbar-input {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}
.recykal .border-danger {
  border: solid 1px var(--ion-color-danger);
}
.recykal .border-right-light {
  border-right: solid 1px var(--ion-color-light);
}
.recykal fieldset.heading-fieldset {
  padding: 0;
  border-color: var(--ion-color-light);
  border-style: solid;
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  border-top-width: 2px;
}
.recykal fieldset.heading-fieldset legend {
  padding: 0 15px;
  text-align: center;
  color: var(--ion-color-dark);
}
.recykal .rotate-45 {
  transform: rotate(45deg);
}
.recykal .rotate-270 {
  transform: rotate(270deg);
}

.recykal.recykal-desktop .item-radio-selectable {
  margin: 5px 10px;
}
.recykal.recykal-desktop .lds-ripple {
  z-index: 0 !important;
}
.recykal.recykal-desktop .location-icon {
  z-index: 9;
}
.recykal.recykal-desktop .add-new-sec {
  background: #F4F9FA;
  margin: 5px 10px;
  min-height: 93%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  cursor: pointer;
}
.recykal.recykal-desktop .add-new-sec .add-new-icon-txt {
  line-height: 12px;
}
.recykal.recykal-desktop .add-new-sec .add-new-icon-txt ion-icon {
  height: 40px;
  width: 40px;
  color: #BAC4C9;
}
.recykal.recykal-desktop .add-new-sec .add-new-icon-txt ion-text {
  display: block;
  font-size: 0.9231rem;
  color: #BAC4C9;
  font-family: "Font Bold";
}
.recykal.recykal-desktop .sidebar {
  background: #f2f2f2;
  margin-top: 5px;
}
.recykal.recykal-desktop .sidebar .sidebar-content {
  margin: 10px 15px 15px 15px;
  height: 100%;
  background: #fff;
  border-radius: 6px;
}
.recykal.recykal-desktop .sidebar ion-list {
  border-radius: 8px;
  height: 100%;
  position: relative;
}
.recykal.recykal-desktop .sidebar ion-list ion-item {
  color: #5B7583;
  font-size: 0.9231rem;
  cursor: pointer;
}
.recykal.recykal-desktop .sidebar ion-list ion-item img {
  margin-right: 10px;
  width: 16px;
  height: auto;
}
.recykal.recykal-desktop .sidebar ion-list .back-arrow-img {
  position: absolute;
  bottom: 15px;
  left: 50%;
  font-size: 24px;
  transform: translateX(-50%);
}
.recykal.recykal-desktop .outlet-content main {
  margin-top: 10px;
}
.recykal.recykal-desktop *[page-bg-colored] {
  --background: #ffffff;
  background: #ffffff;
}
.recykal.recykal-desktop *[page-bg-colored] ion-refresher {
  background: #ffffff;
}
.recykal.recykal-desktop .settings-desktop-screen ion-tab-bar {
  justify-content: start;
  --background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
  min-height: 9%;
  --border: 0px;
}
.recykal.recykal-desktop .settings-desktop-screen ion-tab-bar ion-tab-button {
  --color-selected: var(--ion-color-primary);
  --color: var(--ion-color-medium);
}
.recykal.recykal-desktop .settings-desktop-screen ion-tab-bar ion-tab-button ion-label {
  font-size: 1.077rem !important;
  line-height: 20px;
  padding-bottom: 5px;
  display: inherit;
}
.recykal.recykal-desktop .settings-desktop-screen ion-tab-bar ion-tab-button ion-icon[name=add-circle] {
  font-size: 3.429rem;
}
.recykal.recykal-desktop .settings-desktop-screen ion-tab-bar .tab-selected ion-label {
  color: #27353C !important;
  border-bottom: 2px solid #27353C;
  font-family: Font Bold;
}
.recykal.recykal-desktop form .item-label ion-input, .recykal.recykal-desktop form .item-label ion-textarea {
  font-size: 1.077rem;
  color: #27353C !important;
  background: #F4F9FA;
  border-radius: 6px;
}
.recykal.recykal-desktop form .item-label ion-input ion-icon, .recykal.recykal-desktop form .item-label ion-textarea ion-icon {
  background: #f4f9fa !important;
}
.recykal.recykal-desktop form .item-label ion-label {
  color: #7A7E81;
  font-size: 0.9231rem;
}
.recykal.recykal-desktop .login-screen .ion-input-button {
  --background: #F4F9FA;
}
.recykal.recykal-desktop .login-screen form {
  height: 100%;
  display: flex;
}
.recykal.recykal-desktop .login-screen form ion-row {
  height: 100%;
}
.recykal.recykal-desktop .login-screen form ion-row .save-btn-section {
  display: flex;
  align-items: flex-end;
}
.recykal.recykal-desktop .login-screen form ion-row .save-btn-section ion-button {
  width: 372px;
  margin: 0 auto !important;
  display: block;
}
.recykal.recykal-desktop .verify-icon-margin {
  background: #F4F9FA;
}
.recykal.recykal-desktop .support-cards {
  background: #F4F9FA;
  border-radius: 6px;
  min-height: 114px;
  width: 194px;
  margin: 0 10px 10px 0;
  position: relative;
}
.recykal.recykal-desktop .support-cards ion-text, .recykal.recykal-desktop .support-cards span {
  display: block;
}
.recykal.recykal-desktop .support-cards span {
  font-size: 1rem;
  color: #27353C;
  margin-bottom: 8px;
  font-family: "Font Bold";
}
.recykal.recykal-desktop .support-cards ion-text {
  font-size: 0.9231rem;
  color: #27353C;
}
.recykal.recykal-desktop .support-cards ion-icon {
  position: absolute;
  top: -20px;
  font-size: 47px;
  left: 50%;
  transform: translateX(-50%);
}
.recykal.recykal-desktop .support-cards .contact-txt {
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.recykal.recykal-desktop .store-listing .item-radio-selectable {
  background: #F4F9FA;
  height: 93%;
  margin: 5px 10px;
  cursor: pointer;
}
.recykal.recykal-desktop .store-listing .item-radio-selected {
  height: 93% !important;
}
.recykal.recykal-desktop .cursor-pointer {
  cursor: pointer;
}
.recykal.recykal-desktop .ui-table-responsive tr .cursor-pointer {
  font-size: 12px;
  color: #27353C;
  font-family: Font Bold;
  cursor: pointer;
}
.recykal.recykal-desktop .ui-table-responsive tr th, .recykal.recykal-desktop .ui-table-responsive tr td {
  font-size: 0.9231rem !important;
}
.recykal.recykal-desktop .ui-table-responsive tr th .address-box, .recykal.recykal-desktop .ui-table-responsive tr td .address-box {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.recykal.recykal-desktop .ui-table-responsive tr th .address-box.line-1, .recykal.recykal-desktop .ui-table-responsive tr td .address-box.line-1 {
  -webkit-line-clamp: 1;
}
.recykal.recykal-desktop .ui-table-responsive tr th .address-box.line-2, .recykal.recykal-desktop .ui-table-responsive tr td .address-box.line-2 {
  -webkit-line-clamp: 2;
}
.recykal.recykal-desktop .ui-table-responsive tr th .address-box.line-3, .recykal.recykal-desktop .ui-table-responsive tr td .address-box.line-3 {
  -webkit-line-clamp: 3;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .status-dot {
  display: none;
}
.recykal.recykal-desktop .ui-table-responsive .status-display div:nth-child(2) {
  padding: 5px 10px;
  color: #fff;
  border-radius: 4px;
  font-size: 0.7692rem;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .created + div {
  background: #72abea;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .invoice_pending + div {
  background: #ffef8c;
  color: #000;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .cancelled + div {
  background: #f2503b;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .payment_pending + div {
  background: #fcd974;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .invoice_verification.pending + div {
  background: #fbc45b;
  color: #fff;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .payment_completed + div {
  background: #54b468;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .picked + div {
  background: #bee2c6;
  color: #000;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .scheduled + div {
  background: #ffa25f;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .received + div {
  background: #89cb97;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .on_hold + div {
  background: #ffd378;
  text-transform: uppercase;
  font-family: Font Bold;
}
.recykal.recykal-desktop .ui-table-responsive .status-display .invoice_rejected + div {
  background: #ff7a5f;
  text-transform: uppercase;
  font-family: Font Bold;
  color: #fff;
}
.recykal.recykal-desktop .ui-table-responsive .ui-table-tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.recykal.recykal-desktop .ui-table-responsive input[type=text], .recykal.recykal-desktop .ui-table-responsive input[type=search] {
  background-image: url("./assets/imgs/search-icon-grey.svg") !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: 20px !important;
}
.recykal.recykal-desktop .ui-table-responsive input[type=text]:focus, .recykal.recykal-desktop .ui-table-responsive input[type=search]:focus {
  background-image: none !important;
}
.recykal.recykal-desktop .ui-table-responsive input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}
.recykal.recykal-desktop .ui-table-responsive input[type=search]:hover {
  background-image: none !important;
}
.recykal.recykal-desktop ion-button {
  font-size: inherit;
  font-family: inherit;
  font-weight: normal;
  line-height: 20px;
  text-transform: none;
  width: auto;
  max-height: 52px;
  --box-shadow: none;
  --border-radius: 10px 10px 10px 10px;
  margin-bottom: inherit;
  letter-spacing: 0;
}
.recykal.recykal-desktop ion-footer ion-button {
  font-size: inherit;
  font-family: inherit;
  font-weight: normal;
  line-height: 20px;
  text-transform: none;
  width: 372px;
  margin: 0 auto !important;
  max-height: 52px;
  --box-shadow: 2px 15px 14px -9px var(--ion-color-primary-tint);
  --border-radius: 10px 10px 10px 10px;
  letter-spacing: 0;
  display: block;
}
.recykal.recykal-desktop .view-btn {
  margin-bottom: 0;
}
.recykal.recykal-desktop .view-btn ion-icon {
  height: 16px;
  width: 16px;
  color: #5B7583;
}
.recykal.recykal-desktop .view-btn.proforma-downloaf-btn {
  --padding-end: 0;
}
.recykal.recykal-desktop .view-btn.proforma-downloaf-btn ion-icon {
  margin-left: 0;
}
.recykal.recykal-desktop .earning-summery {
  height: 100%;
  display: flex;
  align-items: center;
  background-size: 50% 50%;
  border-radius: 6px;
  background-repeat-x: repeat;
}
.recykal.recykal-desktop .earning-summery.summary-no-order {
  background-repeat-y: no-repeat;
  background-size: auto 45%;
  background-color: #438e69;
  background-position: bottom;
  min-height: 148px;
  display: flex;
  background-repeat-x: repeat;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/imgs/desktop-footer-bg.png");
}
.recykal.recykal-desktop .earning-summery.summary-no-order .total-earning-section {
  width: 100%;
}
.recykal.recykal-desktop .upcoming-pickup {
  height: 100%;
}
.recykal.recykal-desktop .upcoming-pickup .desktop-pickup-time * {
  text-align: left;
}
.recykal.recykal-desktop .store-addr {
  font-size: 0.8571rem !important;
}
.recykal.recykal-desktop .store-name {
  font-size: 1rem;
}
.recykal.recykal-desktop .card-top .active, .recykal.recykal-desktop .card-top .inactive {
  font-size: var(--ion-x-small-font-size);
  font-family: "Font Bold";
  letter-spacing: 1.5px;
}
.recykal.recykal-desktop ion-list ion-list-header {
  --background: #F4F9FA !important;
  cursor: pointer;
}
.recykal.recykal-desktop input::-webkit-outer-spin-button,
.recykal.recykal-desktop input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.recykal.recykal-desktop .display-flex-rule {
  justify-content: center;
}
.recykal.recykal-desktop .modal-page .modal-wrapper {
  border-radius: 20px;
  position: absolute;
  bottom: unset;
}
.recykal.recykal-desktop .modal-page.desktop-verify-modal .modal-wrapper {
  right: 3%;
}
.recykal.recykal-desktop .desktop-pickup-btn {
  font-family: "Font Bold";
  font-size: 1.385rem;
  margin-right: 20px;
}
.recykal.recykal-desktop .desktop-login-page {
  --background: transparent;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section {
  height: 100%;
  display: flex;
  align-items: center;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid {
  background: rgba(153, 169, 178, 0);
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid ion-row {
  width: 430px;
  margin: 0 auto;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item {
  --background: #dce3e7;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item .login-txt-upper-content {
  text-align: center;
  font-size: 1.429rem !important;
  margin-bottom: 30px;
  font-family: Font Regular;
  color: #27353C;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item.mobileimg {
  width: 20px;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item.mobile ion-input {
  background-color: #fff;
  font-size: 1.692rem;
  font-family: Font Regular;
  --padding-top: 15px !important;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item.mobile ion-input ::-webkit-input-placeholder {
  font-size: 1rem;
  position: relative;
  top: -3px;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item.mobile ion-input ion-text {
  font-size: 1.692rem;
  font-family: Font Regular;
  border: none;
  margin: -3px 10px 0 10px;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .custom-input-item ion-input {
  background-color: #fff;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .login-txt-non-register .no-account-ques {
  text-align: center;
  font-size: 1.385rem;
  margin-bottom: 30px;
  font-family: Font Regular;
  margin-top: 20px;
  margin-bottom: 10px;
  display: block;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .login-txt-non-register .use-email-ph {
  display: block;
  font-size: 1.286rem;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .forgot-text {
  text-align: center;
  font-size: 1.385rem;
  margin-bottom: 30px;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .reset-text {
  text-align: center;
  font-size: 1.385rem;
  margin-bottom: 30px;
  color: var(--ion-color-primary);
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .try-with-oemail {
  font-size: 1.231rem;
}
.recykal.recykal-desktop .desktop-login-page .desktop-login-section .main-grid .try-with-oemail span {
  color: var(--ion-color-primary);
}
.recykal.recykal-desktop .ion-radio-custom {
  display: flow-root;
}
.recykal.recykal-desktop .ion-radio-custom .desktop-product-item {
  width: 33%;
  float: left;
}
.recykal.recykal-desktop .cart-product-screen ion-item-sliding {
  border-radius: 0;
  width: inherit;
  display: inline;
}
.recykal.recykal-desktop .cart-product-screen .positive-cart-item, .recykal.recykal-desktop .cart-product-screen .negative-cart-item {
  width: 48%;
  --background: #F4F9FA;
  float: left;
  border-radius: 6px;
  margin: 0 1% 1% 1%;
  /*      height: 100%;
        display: flex;*/
}
.recykal.recykal-desktop .cart-product-screen .positive-cart-item ion-label .product-name, .recykal.recykal-desktop .cart-product-screen .negative-cart-item ion-label .product-name {
  font-size: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.recykal.recykal-desktop .cart-product-screen .positive-cart-item ion-label .qty, .recykal.recykal-desktop .cart-product-screen .negative-cart-item ion-label .qty {
  font-size: 1rem;
}
.recykal.recykal-desktop .cart-product-screen .positive-cart-item .desktop-cart-item-actions .action-icon, .recykal.recykal-desktop .cart-product-screen .negative-cart-item .desktop-cart-item-actions .action-icon {
  color: #BAC4C9;
  font-size: 1.769rem;
  margin-left: 5px;
}
.recykal.recykal-desktop .product-name p:first-child span {
  color: #5B7583;
  font-size: 1rem;
}
.recykal.recykal-desktop .product-name p:last-child span {
  font-size: 0.8571rem;
  font-family: Font Regular;
}
.recykal.recykal-desktop .desktop-summary-section {
  background: #F4F9FA;
  border-radius: 6px;
  margin-bottom: 10px;
}
.recykal.recykal-desktop .desktop-summary-section ion-row .summary-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.recykal.recykal-desktop .desktop-summary-section ion-row .summary-section .edit-btn {
  margin-left: 10px;
  --background: #FAFAFA;
  --border-color: #dce3e7;
  font-size: 0.9231rem;
  line-height: 10px;
  letter-spacing: 1.5px;
  min-width: 61px;
  min-height: 26.38px;
  --color-activated: #465A65;
  color: #465A65;
  font-family: "Font Bold";
  --border-radius: 6px;
  --border-width: 1px;
}
.recykal.recykal-desktop .cart-item-list .add-more-section {
  align-items: center;
  display: flex;
  justify-content: center;
}
.recykal.recykal-desktop .cart-item-list .add-more-section .add-more-btn {
  margin-left: 20px;
}
.recykal.recykal-desktop .date {
  height: 32px;
}
.recykal.recykal-desktop .date div div {
  padding-top: 4px !important;
}
.recykal.recykal-desktop .item-radio-selectable {
  margin: 0;
}
.recykal.recykal-desktop .ngx-smart-dialog-full .nsm-dialog-open {
  width: 100%;
  max-width: 85%;
}
.recykal.recykal-desktop .ngx-smart-dialog-full .nsm-dialog-btn-close {
  border-radius: 50%;
  left: unset !important;
  right: 10px;
  background: #dce3e7;
  width: 21px;
  height: 21px;
}
.recykal.recykal-desktop .ngx-smart-dialog-full .nsm-dialog-btn-close svg {
  height: 11px;
  color: white;
  width: 9px;
}
.recykal.recykal-desktop .bottom-sheet .modal-wrapper {
  width: 80%;
  height: 80%;
  border-radius: 10px;
  bottom: unset;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper {
  top: 60px;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page {
  display: flex;
  height: 100%;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .close-btn {
  margin-top: 10px;
  margin-right: 10px;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .close-btn ion-icon {
  cursor: pointer;
  color: #dce3e7;
  font-size: 21px;
  float: right;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content {
  max-height: 100%;
  height: 100%;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .pickup-flow {
  height: 100%;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .pickup-flow .pickup-flow-row {
  height: 100%;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .content-header-section {
  padding-top: 0;
  padding-left: 15px;
  padding-bottom: 10px;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .content-header-section .main-title {
  font-size: 1.286rem;
  font-family: Font Bold;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .content-header-section .sub-title {
  font-size: 1rem;
  color: #5B7583;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .desktop-qty-main-section {
  background-color: #F4F9FA;
  border-radius: 6px;
  margin-left: 15px;
  min-height: 86%;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .desktop-qty-main-section .product-data-top {
  background-color: #F4F9FA;
  padding: 20px 20px 0 20px;
}
.recykal.recykal-desktop .bottom-sheet.desktop-qty-modal .modal-wrapper .ion-page .inner-content .desktop-qty-main-section .product-data-bottom {
  background-color: #F4F9FA;
  padding: 20px 20px 20px 20px;
}
.recykal.recykal-desktop .custom-bottom-sheet .modal-wrapper {
  bottom: unset;
  border-radius: 6px;
}
.recykal.recykal-desktop .custom-bottom-sheet.desktop-onboard-error .modal-wrapper {
  bottom: unset;
  border-radius: 6px;
  height: 160px;
  width: 574px;
}
.recykal.recykal-desktop .transactions-screen {
  /*.all-transactions {
    height: 100% !important;
    margin: 0 5px;
  }*/
}
.recykal.recykal-desktop .transactions-screen .main-grid {
  height: 100% !important;
  margin: 0 5px;
}
.recykal.recykal-desktop .transactions-screen .order-section {
  margin-top: -15px;
}
.recykal.recykal-desktop .transactions-screen.empty-desktop-orders .order-section {
  margin-top: 0;
  height: 100%;
}
.recykal.recykal-desktop .payments-screen .order-section {
  margin-top: -25px;
}
.recykal.recykal-desktop .button-box-shadow {
  font-weight: normal !important;
  line-height: 20px !important;
  text-transform: none !important;
  width: 35% !important;
  max-height: 52px !important;
  --border-radius: 10px 10px 10px 10px !important;
  margin-bottom: inherit !important;
  letter-spacing: 0 !important;
  --box-shadow: 2px 15px 14px -9px var(--ion-color-primary-tint) !important;
}
.recykal.recykal-desktop .icon-size {
  width: 70px;
  height: 70px;
}
.recykal.recykal-desktop .margin-top-18 {
  margin-top: 18px;
}
.recykal.recykal-desktop .text-size {
  font-size: 1.429rem;
}
.recykal.recykal-desktop .background-color-col {
  background-color: #F4F9FA !important;
}
.recykal.recykal-desktop .apply-background1 {
  background: url("./assets/imgs/no-1.svg") no-repeat 100% 100% var(--ion-color-primary-contrast);
  background-position-y: 35%;
  background-position-x: 15%;
}
.recykal.recykal-desktop .apply-background2 {
  background: url("./assets/imgs/no-2.svg") no-repeat 100% 100% var(--ion-color-primary-contrast);
  background-position-y: 35%;
  background-position-x: 15%;
}
.recykal.recykal-desktop .apply-background3 {
  background: url("./assets/imgs/no-3.svg") no-repeat 100% 100% var(--ion-color-primary-contrast);
  background-position-y: 35%;
  background-position-x: 15%;
}
.recykal.recykal-desktop .apply-background4 {
  background: url("./assets/imgs/no-4.svg") no-repeat 100% 100% var(--ion-color-primary-contrast);
  background-position-y: 35%;
  background-position-x: 15%;
}
.recykal.recykal-desktop .apply-background5 {
  background: url("./assets/imgs/no-5.svg") no-repeat 100% 100% var(--ion-color-primary-contrast);
  background-position-y: 35%;
  background-position-x: 15%;
}
.recykal.recykal-desktop .margin-0 {
  margin: 0 !important;
}
.recykal.recykal-desktop .apply-background6 {
  background: url("./assets/imgs/no-6.svg") no-repeat 100% 100% var(--ion-color-primary-contrast);
  background-position-y: 35%;
  background-position-x: 15%;
}
.recykal.recykal-desktop .border-top-radius {
  background-color: #F4F9FA;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.recykal.recykal-desktop .border-bottom-radius {
  background-color: #F4F9FA;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 30px;
}
.recykal.recykal-desktop .desktop-white-color {
  color: #FFFFFF !important;
  --color: #FFFFFF !important;
}
.recykal.recykal-desktop .minimise .sidebar {
  width: 7vw !important;
  height: 85vh;
  position: relative;
  transition: 0.5s;
}
.recykal.recykal-desktop .minimise .outlet {
  max-width: 93vw;
  height: 85vh;
  flex: 1;
  position: relative;
  margin: 15px 15px 15px 0;
}
.recykal.recykal-desktop .minimise .outlet .outlet-content {
  border-radius: 8px;
}
.recykal.recykal-desktop .minimise ion-label {
  transition: 0.2s;
  opacity: 0;
  width: 0px;
  margin: 0 !important;
}
.recykal.recykal-desktop .minimise .back-arrow-img ion-icon {
  margin-left: 0 !important;
  transform: rotate(180deg);
}
.recykal.recykal-desktop .desktop-header-options ion-list ion-item {
  --min-height: 40px;
}
.recykal.recykal-desktop .desktop-header-options ion-list ion-item ion-label {
  cursor: pointer;
  color: #27353C;
  font-size: 1.077rem !important;
  text-align: center;
  font-family: Font Regular;
  margin: 0;
  padding: 0;
}
.recykal.recykal-desktop #map_canvas {
  height: 100% !important;
  border-radius: 6px;
}
.recykal.recykal-desktop .add-store-form-container ion-list ion-list-header {
  background: #5B7583;
  border-radius: 6px !important;
  color: #fff;
}
.recykal.recykal-desktop .forgot-pwd-screen {
  --background: transparent;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen {
  --background: transparent;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0;
  margin: 0;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section ion-row {
  background: #dce3e7;
  width: 430px;
  margin: 0 auto;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section ion-row ion-list {
  background: #dce3e7;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section ion-row ion-list ion-item {
  --background: #dce3e7;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section ion-row ion-list ion-item.custom-input-item ion-label {
  color: #27353C;
  font-size: 1.077rem !important;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section ion-row ion-list ion-item.custom-input-item ion-input {
  background-color: #ffffff;
}
.recykal.recykal-desktop .desktop-forgot-pwd-screen .desktop-forgot-pwd-section ion-row .login-txt-upper-content {
  text-align: center;
  font-size: 1.429rem !important;
  margin-bottom: 30px;
  font-family: Font Regular;
  color: #27353C;
}
.recykal.recykal-desktop .desktop-item-search {
  display: flex;
  align-items: center;
  justify-content: center;
  --min-height: 34px;
  border: 1px solid #5B7583;
  border-radius: 6px;
  height: 34px;
  --highlight-color-valid: #ffffff;
}
.recykal.recykal-desktop .desktop-item-search ion-input {
  background: #ffffff;
  height: 34px;
  margin-top: 0;
}
.recykal.recykal-desktop .desktop-forgot-pwd-sent-modal .nsm-dialog-btn-close {
  right: 8px !important;
  left: unset !important;
}
.recykal.recykal-desktop .kgs-recykal-platform {
  background-image: url("./assets/imgs/desktop-footer-bg.png");
  background-repeat-y: no-repeat;
  background-size: 500px 50%;
  background-color: #438e69;
  background-position: bottom;
  min-height: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-repeat-x: repeat;
  margin-top: 10px !important;
}
.recykal.recykal-desktop .kgs-recykal-platform ion-text, .recykal.recykal-desktop .kgs-recykal-platform ion-icon, .recykal.recykal-desktop .kgs-recykal-platform .odometer-formatting-mark {
  color: #ffffff;
}
.recykal.recykal-desktop .bg-getting-started .applyBackground1, .recykal.recykal-desktop .bg-getting-started .applyBackground2, .recykal.recykal-desktop .bg-getting-started .applyBackground3, .recykal.recykal-desktop .bg-getting-started .applyBackground4, .recykal.recykal-desktop .bg-getting-started .applyBackground5, .recykal.recykal-desktop .bg-getting-started .applyBackground6 {
  margin: 20px auto;
}
.recykal.recykal-desktop .quick-actions ion-button {
  --border-radius: 6px;
  height: 29px;
  font-size: 12px;
}
.recykal.recykal-desktop .ui-datepicker-calendar tr:nth-child(2) {
  border-bottom: none !important;
}
.recykal.recykal-desktop .desktop-footer-section {
  box-shadow: 0px -4px 2px 0px #5b75830d;
  padding-bottom: 0;
  padding-top: 10px;
  min-height: 70px;
  border-radius: 8px;
}
.recykal.recykal-desktop .desktop-footer-section.with-padding {
  padding-bottom: 20px;
}
.recykal.recykal-desktop .desktop-footer-section.from-pickup-flow {
  position: fixed;
  bottom: 65px !important;
  width: 80%;
  border-radius: 0 0 10px 0;
  z-index: 15;
  left: 10%;
  padding-right: 15px;
}
.recykal.recykal-desktop .desktop-footer-section.from-manage-locations {
  position: fixed;
  bottom: 0 !important;
  background: white;
  border-radius: 0 0 10px 0;
  float: right;
  right: 0;
  padding-right: 15px;
  z-index: 15;
}
.recykal.recykal-desktop .desktop-footer-section .next-step {
  padding: 0;
}
.recykal.recykal-desktop .desktop-footer-section .next-step ion-button {
  width: 274px;
  float: right;
  height: 46px;
  --border-radius: 6px;
  --background-color: #328E69;
  --box-shadow: none;
  opacity: 1;
  font-size: 1.286rem;
  font-family: Font Semi Bold;
}
.recykal.recykal-desktop .desktop-footer-section .next-step ion-button .next-icon {
  background: #fff;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-left: 5px;
}
.recykal.recykal-desktop .desktop-footer-section .next-step ion-button .next-icon ion-icon {
  vertical-align: middle;
  color: var(--ion-color-primary);
}
.recykal.recykal-desktop .desktop-footer-section .next-step .float-unset {
  float: unset !important;
}
.recykal.recykal-desktop .desktop-footer-section .back-step ion-button {
  width: 125px;
  float: left;
  height: 46px;
  --border-radius: 6px;
  --background: #F4F9FA;
  --background-activated: #F4F9FA;
  --background-focused: #F4F9FA;
  --background-hover: #F4F9FA;
  --box-shadow: none;
  color: #27353C;
  opacity: 1;
  font-size: 1.286rem;
  font-family: Font Semi Bold;
}
.recykal.recykal-desktop .desktop-footer-section .back-step ion-button .back-icon {
  background: #27353C;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  margin-right: 5px;
}
.recykal.recykal-desktop .desktop-footer-section .back-step ion-button .back-icon ion-icon {
  vertical-align: middle;
  color: #fff;
}
.recykal.recykal-desktop .desktop-dashbaord {
  --padding-start: 0;
  --padding-end: 0;
}
.recykal.recykal-desktop .desktop-dashbaord .data-section {
  padding: 0;
  background: linear-gradient(to bottom, #f2f2f2 0%, #f2f2f2 50%, #ffffff 100%);
}
.recykal.recykal-desktop .desktop-dashbaord .data-section #ignore-in-report1 {
  padding-left: 0;
  margin-bottom: 10px;
}
.recykal.recykal-desktop .desktop-dashbaord .data-section #ignore-in-report2 {
  padding-right: 0;
  margin-bottom: 10px;
}
.recykal.recykal-desktop .desktop-dashbaord .data-section #total-report {
  padding: 0;
}
.recykal.recykal-desktop .desktop-dashbaord .data-section #total-report .download-report-btn {
  position: absolute;
  z-index: 9;
  right: 0px;
  top: 0px;
  --background: #fff;
  --background-activated: #fff;
  --background-focused: #fff;
  --background-hover: #fff;
  --color: #000;
  --color-activated: #000;
  --color-focused: #000;
  --color-hover: #000;
  font-size: 18px;
}
.recykal.recykal-desktop input-errors, .recykal.recykal-desktop *[input-errors-custom] {
  font-size: 12px;
}
.recykal.recykal-desktop .support-screen .content-header-section {
  padding-top: 0;
  padding-bottom: 25px;
}
.recykal.recykal-desktop .support-screen .content-header-section .main-title {
  font-size: 1.286rem;
  font-family: Font Bold;
}
.recykal.recykal-desktop .support-screen .content-header-section .sub-title {
  font-size: 1rem;
  color: #5B7583;
}
.recykal.recykal-desktop .custom-odometer .odometer-theme-train-station .odometer-digit, .recykal.recykal-desktop .custom-odometer .odometer-theme-train-station .odometer-formatting-mark {
  display: inline-block;
}
.recykal.recykal-desktop .desktop-custom-title {
  font-family: "Font Bold";
  font-size: 1.385rem;
}
.recykal.recykal-desktop .add-edit-location-screen .add-edit-form.from-manage-locations-grid {
  margin-bottom: 80px;
}
.recykal.recykal-desktop .add-edit-location-screen .add-edit-form.from-manage-locations-grid .from-manage-locations-section {
  padding-top: 0;
}
.recykal.recykal-desktop .add-edit-location-screen .add-edit-form.from-manage-locations-grid .from-manage-locations-section .status-toggle-col {
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 15;
  width: 40% !important;
}
.recykal.recykal-desktop .add-edit-location-screen .add-edit-form.from-manage-locations-grid .from-manage-locations-section .address-box {
  padding-top: 0;
  margin-top: -15px;
}

.recykal {
  /* Orientation landscape doesn't matter what height it is*/
  /* Standard resolutions */
  /* Extra small devices (phones, 600px and down) */
  /* Small devices (portrait tablets and large phones, 600px and up) */
  /* Medium devices (landscape tablets, 768px and up) */
  /* Large devices (laptops/desktops, 992px and up) */
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  /* Device specific resolutions */
  /* ----------- iPhone 4 and 4S ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPhone 6+, 7+ and 8+ ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPhone X ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Galaxy S3 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Galaxy S4, S5 and Note 3 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Galaxy S6 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Google Pixel ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Google Pixel XL ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- HTC One ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Windows Phone ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPad 1, 2, Mini and Air ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPad 3, 4 and Pro 9.7" ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- iPad Pro 10.5" ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  /* ----------- iPad Pro 12.9" ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  /* Landscape */
  /* Declare the same value for min- and max-width to avoid colliding with desktops */
  /* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
  /* ----------- Galaxy Tab 2 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Galaxy Tab S ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Nexus 7 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Nexus 9 ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Kindle Fire HD 7" ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
  /* ----------- Kindle Fire HD 8.9" ----------- */
  /* Portrait and Landscape */
  /* Portrait */
  /* Landscape */
}
@media only screen and (max-device-height: 669px) {
  .recykal *[page-bg-img] {
    --background: unset;
  }
  .recykal .bottom-img {
    display: none;
  }
}
@media screen and (orientation: landscape) {
  .recykal .page-content-center {
    top: unset;
    transform: unset;
  }
  .recykal *[page-bg-img] {
    --background: unset;
  }
  .recykal .bottom-img {
    display: none;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .recykal .bottom-img {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
}
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
  .recykal.recykal-desktop .desktop-commom-footer {
    line-height: 0;
    position: static;
    height: 100px;
    background: url("./assets/imgs/desktop-footer-bg.png");
    background-size: contain;
    bottom: 0;
    background-color: #fff;
    /*margin-right: -15px;
    margin-left: -15px;*/
    margin-top: 10px;
  }
}
@media only screen and (min-width: 1370px) and (max-width: 1605px) {
  .recykal.recykal-desktop .desktop-commom-footer {
    line-height: 0;
    position: absolute;
    height: 100px;
    background: url("./assets/imgs/desktop-footer-bg.png");
    background-size: contain;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .recykal.recykal-desktop .pickup-flow-content .content-data-section {
    height: 81% !important;
  }
}

:root .ui-widget {
  font-family: inherit !important;
}
:root .ui-widget .ui-table-loading-content {
  top: 130px !important;
}
:root .ui-widget.ui-table table {
  table-layout: auto;
}
:root .ui-widget.ui-table table.ui-table-scrollable-header-table, :root .ui-widget.ui-table table.ui-table-scrollable-body-table {
  table-layout: fixed !important;
}
:root .ui-widget.ui-table table .ui-table-tbody > tr > td {
  border: none !important;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) {
  border-bottom: 2px solid #dcdcdc;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) th {
  background-color: #FFFFFF !important;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) input, :root .ui-widget.ui-table table thead tr:nth-child(2) select, :root .ui-widget.ui-table table thead tr:nth-child(2) p-multiselect .ui-multiselect-label {
  overflow: hidden;
  height: 28px;
  padding-left: 10px;
  padding-right: 10px;
  background: #FFFFFF;
  font-size: 0.867rem;
  width: 100%;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  display: -webkit-box;
  border: 1px solid #E1E6E8;
  color: #000000;
  border-radius: 5px;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) input[type=text], :root .ui-widget.ui-table table thead tr:nth-child(2) input[type=search] {
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: 20px !important;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) input[type=text]:focus, :root .ui-widget.ui-table table thead tr:nth-child(2) input[type=search]:focus {
  background-image: none !important;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}
:root .ui-widget.ui-table table thead tr:nth-child(2) input[type=search]:hover {
  background-image: none !important;
}
:root .ui-widget tbody tr.ui-state-highlight,
:root .ui-widget thead th.ui-state-highlight {
  background-color: transparent;
  color: inherit !important;
}
:root .ui-widget thead tr th {
  border-left: none;
  border-right: none;
  border-top: none;
  background-color: #fff;
  color: #4e4d4d;
  text-align: left;
  border: none;
}
:root .ui-widget thead tr th .ui-sortable-column.ui-state-highlight, :root .ui-widget thead tr th .ui-sortable-column:hover {
  background: none;
}
:root .ui-widget thead tr th .ui-sortable-column-icon {
  color: inherit !important;
}
:root .ui-widget thead tr th .ui-sortable-column-icon:before {
  font-size: 0.8571rem;
}
:root .ui-widget thead tr th p {
  margin-top: 6px;
  margin-bottom: 6px;
}
:root .ui-widget tbody td {
  border-left: none;
  border-right: none;
  border-bottom: none;
  background-color: white;
  height: 45px;
  text-align: left;
  color: var(--ion-color-medium);
  font-size: 0.8571rem;
}
:root .ui-widget tbody td ion-text {
  line-height: 16px;
}
:root .ui-widget tbody td ion-text.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:root .ui-widget tbody tr {
  background: none;
  height: 52px;
}
:root .ui-widget tbody tr p {
  line-height: 17px;
  font-size: 0.8571rem;
}
:root .ui-widget .ui-paginator-bottom {
  padding: 2px;
  border: none;
  border-top: unset !important;
  background: #fff;
  /*      position: fixed;
        bottom: 25px;
        right: 30px;*/
}
:root .ui-widget .ui-datepicker {
  padding: 3px;
  width: 261px;
}
:root .ui-widget .ui-datepicker table {
  font-size: 0.7857rem;
}
:root .ui-widget .ui-datepicker table td {
  padding: 0;
}
:root .ui-widget .ui-paginator {
  border: 1px solid #C7D2D8 !important;
  width: auto;
  float: right;
  margin: 10px 0;
  border-radius: 5px;
}
:root .ui-widget .ui-paginator .ui-paginator-pages .ui-paginator-page {
  font-size: 0.8571rem;
}
:root .ui-widget .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: var(--ion-color-medium);
  border-radius: 5px;
  font-size: 0.8571rem;
}
:root .ui-widget .ui-paginator .ui-paginator-first, :root .ui-widget .ui-paginator .ui-paginator-last {
  display: none;
}
:root .ui-widget .ui-paginator .ui-paginator-bottom {
  border-top: unset !important;
}
:root .ui-widget .ui-paginator .ui-paginator-prev, :root .ui-widget .ui-paginator .ui-paginator-next {
  font-size: 0.8571rem;
}
:root .ui-widget .ui-paginator .pi-caret-left {
  border-right: 1px solid var(--ion-color-medium);
}
:root .ui-widget .ui-paginator .pi-caret-right {
  border-left: 1px solid var(--ion-color-medium);
}
:root .ui-widget .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: transparent;
}
:root .ui-widget .ui-table-wrapper table thead .ui-dropdown, :root .ui-widget .ui-table-scrollable-wrapper table thead .ui-dropdown {
  min-width: 100px;
}
:root .ui-widget .ui-table-wrapper table thead .ui-dropdown .ui-dropdown-item, :root .ui-widget .ui-table-scrollable-wrapper table thead .ui-dropdown .ui-dropdown-item {
  font-size: var(--ion-regular-font-size);
}
:root .ui-widget .ui-table-wrapper table thead .ui-dropdown .ui-dropdown-trigger-icon, :root .ui-widget .ui-table-scrollable-wrapper table thead .ui-dropdown .ui-dropdown-trigger-icon {
  font-size: 0.8rem;
}
:root .ui-widget .ui-table-wrapper table thead .ui-dropdown label.ui-dropdown-label, :root .ui-widget .ui-table-scrollable-wrapper table thead .ui-dropdown label.ui-dropdown-label {
  font-size: var(--ion-regular-font-size);
  padding: 4px 10px !important;
}
:root .ui-widget .ui-table-wrapper table thead tr th, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th {
  background-color: var(--ion-color-medium) !important;
  color: #fff !important;
  font-size: 0.8571rem;
  font-weight: 300;
  padding-right: 0;
}
:root .ui-widget .ui-table-wrapper table thead tr th:last-child, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th:last-child {
  padding-right: 10px;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col {
  padding: 5px !important;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-autocomplete ul, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-autocomplete ul {
  border: none;
  padding: 0;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-autocomplete ul li.ui-autocomplete-input-token input, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-autocomplete ul li.ui-autocomplete-input-token input {
  padding: 0 10px;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect {
  border: none;
  width: 95%;
  min-width: 100px;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-items-wrapper, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-items-wrapper {
  min-width: 140px;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-label-container .ui-multiselect-label, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-label-container .ui-multiselect-label {
  margin: 0;
  padding: 5px 10px !important;
  color: var(--ion-color-dark);
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-filter-container, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-filter-container {
  background: none;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-filter-container input, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-filter-container input {
  background-image: none !important;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-trigger, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-trigger {
  border: 1px solid #E1E6E8;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
  font-size: 0.8571rem;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .custom-slider-range .selected-values, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .custom-slider-range .selected-values {
  color: var(--ion-color-medium);
  height: 20px;
  margin-bottom: 5px;
  margin-top: -10px;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .custom-slider-range .selected-values .left, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .custom-slider-range .selected-values .left {
  float: left;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .custom-slider-range .selected-values .right, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .custom-slider-range .selected-values .right {
  float: right;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .custom-slider-range .custom-slider-selector, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .custom-slider-range .custom-slider-selector {
  width: 85%;
  margin: 0 auto;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .selected-values, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .selected-values {
  color: var(--ion-color-medium);
  height: 20px;
  margin-bottom: 5px;
  margin-top: -10px;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .selected-values .left, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .selected-values .left {
  float: left;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .selected-values .right, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .selected-values .right {
  float: right;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .custom-slider-selector, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .custom-slider-selector {
  width: 85%;
  margin: 0 auto;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .ui-dropdown, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .ui-dropdown {
  border-right: 0 !important;
  border-radius: 5px 0px 0 5px;
  min-width: auto;
  border-color: #E1E6E8;
  height: 28px;
  background: #f9f9f9;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .ui-dropdown .ui-dropdown-label, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .ui-dropdown .ui-dropdown-label {
  background: #f9f9f9;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .ui-dropdown .ui-dropdown-trigger, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .ui-dropdown .ui-dropdown-trigger {
  display: none;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .delay-section .delay-text, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .delay-section .delay-text {
  border-radius: 0 5px 5px 0;
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-slider .ui-slider-range, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-slider .ui-slider-range {
  background-color: var(--ion-color-primary);
}
:root .ui-widget .ui-table-wrapper table thead tr th.filter-col .ui-slider .ui-slider-handle, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th.filter-col .ui-slider .ui-slider-handle {
  width: 10px;
  height: 10px;
  border: 1px solid #438e69;
  margin-left: -5px;
}
:root .ui-widget .ui-table-wrapper table thead tr th .ui-datepicker-calendar thead, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th .ui-datepicker-calendar thead {
  display: none;
}
:root .ui-widget .ui-table-wrapper table thead tr th .ui-dropdown, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th .ui-dropdown {
  width: 100%;
}
:root .ui-widget .ui-table-wrapper table thead tr th:first-child, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
:root .ui-widget .ui-table-wrapper table thead tr th:last-child, :root .ui-widget .ui-table-scrollable-wrapper table thead tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
:root .ui-widget .ui-table-wrapper table tbody tr td:first-child, :root .ui-widget .ui-table-scrollable-wrapper table tbody tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
:root .ui-widget .ui-table-wrapper table tbody tr td:last-child, :root .ui-widget .ui-table-scrollable-wrapper table tbody tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
:root .ui-widget .ui-table-wrapper table tbody .bold-text, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bold-text {
  color: var(--ion-color-dark);
  font-family: "font-bold";
  font-size: 0.8571rem;
}
:root .ui-widget .ui-table-wrapper table tbody .grey-text, :root .ui-widget .ui-table-scrollable-wrapper table tbody .grey-text {
  color: var(--ion-color-medium);
  font-size: 0.8571rem;
}
:root .ui-widget .ui-table-wrapper table tbody .delay-text, :root .ui-widget .ui-table-scrollable-wrapper table tbody .delay-text {
  color: var(--ion-color-danger);
  font-size: 0.8571rem;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display {
  display: flex;
  align-items: center;
  line-height: 15px;
  text-transform: capitalize;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot {
  width: 8px;
  height: 8px;
  margin-right: 7px;
  border-radius: 50%;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.created, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.created {
  background-color: #72ABEA;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.completed, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.completed {
  background-color: #54B468;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.on_hold, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.on_hold {
  background-color: #FFD378;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.picked, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.picked {
  background-color: #BEE2C6;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.cancelled, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.cancelled {
  background-color: #F2503B;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.scheduled, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.scheduled {
  background-color: #FFA25F;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.received, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.received {
  background-color: #89CB97;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.invoice_pending, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.invoice_pending {
  background-color: #FFEF8C;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.payment_pending, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.payment_pending {
  background-color: #FCD974;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.invoice_verification_pending, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.invoice_verification_pending {
  background-color: #FBC45B;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.invoice_rejected, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.invoice_rejected {
  background-color: #FF7A5F;
}
:root .ui-widget .ui-table-wrapper table tbody .status-display .status-dot.payment_completed, :root .ui-widget .ui-table-scrollable-wrapper table tbody .status-display .status-dot.payment_completed {
  background-color: #54B468;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span {
  border-radius: 4px;
  font-size: 0.7143rem;
  color: #27353C;
  padding: 3px 8px;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.RETAIL, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.RETAIL {
  background: #FDE6D5;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.RWA, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.RWA {
  background: #FFFBC8;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.ITOFFC, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.ITOFFC {
  background: #FFEFD1;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.HOTEL, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.HOTEL {
  background: #F9D5DC;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.EDU, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.EDU {
  background: #F8CADF;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.HOSPTL, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.HOSPTL {
  background: #EFD1E8;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.FACLTY, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.FACLTY {
  background: #DEDBEF;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.MANU, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.MANU {
  background: #CDDFF9;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.KABADI, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.KABADI {
  background: #B2DDE3;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.OTHER, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.OTHER {
  background: #BCE2FE;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.INDVL, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.INDVL {
  background: #FDE6D5;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.AGGR, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.AGGR {
  background: #C8EFE9;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.RAGPICK, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.RAGPICK {
  background: #FDE6D5;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.RECLR, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.RECLR {
  background: #CCEAF3;
}
:root .ui-widget .ui-table-wrapper table tbody .bgtype span.WHLR, :root .ui-widget .ui-table-scrollable-wrapper table tbody .bgtype span.WHLR {
  background: #FDE6D5;
}
:root .ui-widget .ui-table-wrapper .ui-table .ui-table-tbody > tr > td, :root .ui-widget .ui-table-scrollable-wrapper .ui-table .ui-table-tbody > tr > td {
  border: none !important;
}
:root .ui-widget .ui-table-scrollable-header {
  overflow: visible;
}